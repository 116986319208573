import styled from 'styled-components';
export const StyledEquationDojoPageContent = styled.div`
    position: relative;
	display: flex;
	overflow: ${({ notLimitHeight }) => notLimitHeight ? 'visible' : 'auto'};
	overflow-y: ${({ notLimitHeight }) => notLimitHeight ? 'visible' : 'overlay'};
	margin: 12px 8px;
	border: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
    box-sizing: border-box;
    border-radius: 8px;

	> div {
		height: 100%;
		width: 100%;
		@media (max-width: 1024px) {
			width: 100%;
		}
	}
	.tempDone{
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 28px;
		font-weight: 500;
	}
	@media (max-width: 1024px) {
        margin:0 0;
		border:0px;
		border-radius: 0px;
 	}
`;
export const UiBtnZone = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
	& > div:not(:first-child){
		margin-left: 8px;
		@media (max-width: 425px){
			margin-left: unset;
		}
	}
	 @media (max-width: 1024px) {
		width: 100%;
		background-color: #fff;
		padding: 10px 24px 34px 24px;
        position: fixed;
		bottom: 0;
		box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
		margin: 0 0;
		z-index:999;
		>div{
			width: 100%;
		}
		.prevStepBtn{
			display: none;
		}
		.disabledText{
			color:${({ theme }) => theme.colors.text.text_05} !important;
			>svg{
				color:${({ theme }) => theme.colors.text.text_05} !important;
			}
		}
		.goToSteps5Btn{
			border: 1px solid #EC7963 !important;
		}
		.ant-modal-content{
			width: 100vw;
			height: calc(var(--vh, 1vh) * 100);
			border-radius: 0px;
			
		}
 	}
`;
export const StyledEquationDojoPage = styled.div.attrs(props => ({
  className: props.className
}))`
	.warningBar{
		display: flex;
		align-items: center;
		padding: 12px 16px;
		color:${({ theme }) => theme.colors.text.text_07};
		background: ${({ theme }) => theme.colors.support.middle};
		margin: 0px 8px;
		flex-wrap: wrap;
		div{
			width: 100%;
		}
		ul{
			list-style-type: disc;
			margin:4px 0 0 8px;
		}
		@media (max-width: 1024px) {
			margin: 16px 24px 0 24px;
		}
		svg{
			font-size: 20px;
			color: ${({ theme }) => theme.colors.support.warning};
			@media (max-width: 1024px) {
				font-size: 30px;
			}
		}
	}

    .ant-steps{
        width: 80%;
        margin: auto;
        margin-bottom: 26px;
    }

	.errorBar{
		display: flex;
		align-items: center;
		padding: 12px 16px;
		color:${({ theme }) => theme.colors.support.error};
		background: ${({ theme }) => theme.colors.support.diffculty};
		margin: 0px 8px;
		flex-wrap: wrap;
		div{
			width: 100%;
		}
		ul{
			list-style-type: disc;
			margin:4px 0 0 8px;
		}
		@media (max-width: 1024px) {
			margin: 0 0;
			position: fixed;
			bottom: 84px;
			width: 100%;
			z-index: 1000;
		}
		svg{
			font-size: 20px;
			color: ${({ theme }) => theme.colors.support.error};
			@media (max-width: 1024px) {
				font-size: 20px;
			}
		}
	}

	@media (max-width: 1024px) {
		margin:0 0 10px;
		border: 0px;

        .ant-modal{
			position: absolute;
			bottom: 0;
			max-width:100%;
			padding: 0 0;
			margin: 0 0;
		}
		.ant-drawer-title{
			font-size: 20px;
		}

		#emptyTextModal{
			.ant-modal-content{
				width: 100%;
			}
		}		

		.ant-modal-content{
			position: absolute;
			bottom: 0;
			border-radius: 12px 12px 0 0;
		}
		.ant-modal-header{
			display: flex;
			justify-content:center;
			align-items:center;
			padding: 21px 40px 21px;
		}
		.ant-modal-close-x{
			display: flex;
			justify-content:center;
			align-items:center;
			width: 50px;
			height:63px;
		}
		.ant-modal-body{
			padding: 12px 40px 12px;
		}
		.ant-modal-footer{
			padding: 4px 40px 20px;
		}
		.footerBtn{
			width: 100%;
		}
		.footerBtnBox{
			width: 100%;
			display: flex;
			flex-direction:column-reverse;
			>div{
				width: 100%;
				&:nth-child(2){
					margin: 0 0 12px 0;
				}
			}
		}
	}
`;

