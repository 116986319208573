import styled from 'styled-components';

export const StyledExamPaperSetting = styled.div.attrs(props => ({
  className: props.className
}))`
    > *:last-child {
        margin-bottom: 0;
    }
    .saveTip{
        font-size: 18px;
        color: ${({ theme }) => theme.colors.text.text_06};
        display: flex;
        margin-left: 10px;
    }
    .titleRow{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .dragRowContent{
        display: flex;
        align-items: center;
        min-height: 82px;
        .dragRowCheckBox{
        margin: 0 32px;
        justify-content: center;
        align-items: center;
        }
        .dragRowInnerRow{
        flex: 1;
        padding: 20px 80px 20px 0;
        .label{
            margin-bottom: 8px;
        }
        .warningTag{
            color: ${({ theme }) => theme.colors.support.error};
        }
        }
    }
    .ant-radio-group-solid .ant-radio-button-wrapper{
        padding:8px 16px;
    }
    .radioLabel{
        margin-bottom: 8px;
        color:${({ theme }) => theme.colors.text.text_06};
        
    }
    .iconRadioList{
        svg{
            font-size: 18px;
        }
        >label{
            width: 100px;
            padding: 8px 10px !important;
            >span{
                flex-direction: column;
                >svg{
                    font-size: 50px;
                    margin: 0 0;
                }
            }
        }
    }
    .mutiCheckBox{
        padding:8px 20px;
        border: 1px solid #d9d9d9;
        border-radius: 20px;
        font-size: 14px;
        &:not(:last-child){
        margin-right: 8px;
        }
        & svg{
            margin-right: 0;
        }
        @media (max-width: 1024px) {
            margin: 0 8px 8px 0;
        }
    }
    .classInfo{
            display: flex;
            align-items: start;
            padding: 8px 0 0;
            > div{
                width: 120px;
                margin:0 8px;
                &:first-child{
                    margin:0 8px 0 0;
                }
                @media (max-width: 1024px) {
                    width: 65px;
                }
            }
        }
    .advancedRow{
        display: flex;
        align-items: center;
        .advancedSelect{
            min-width: 80px;
            margin:0 8px;
        }
    }
  .ant-radio-button-wrapper{
      & > span:last-child{
          display: flex;
          align-items: center;
      }
  }
  .ant-alert.ant-alert-warning.ant-alert-banner {
    background: transparent;
    font-size: 14px;
    padding: 8px 0;
    >.anticon {
      width: 14px;
      height: 14px;
      color: #F44336;
    }
    .ant-alert-message {
      color: #F44336;
    }
  }
  .azBlock{
    border: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
    box-sizing: border-box;
    border-radius: 8px;
    display:inline-flex;
    flex-wrap: wrap;
    padding:12px  24px;
    margin-top: 16px;
    > .radioLabel{
        width: 100%;
    }
    @media (max-width: 1024px) {
        .ant-checkbox-wrapper{
            margin: 0 8px 8px 0;
        }
    }
  }
  .checkList{
    .ant-checkbox-disabled + span{
        color:${({ theme }) => theme.colors.text.text_07}
    }
  }
  .switchBox{
      display: flex;
      align-items: center;
      
      span{
          padding-left: 18px;
      }
  }
  .mutiPaperSettingBox{
    margin-top: 16px;
    border: 1px solid #D5D7DE;
    box-sizing: border-box;
    border-radius: 8px;
    padding:16px 24px 0;
  }

  .row {
    background-color: #fff;
    padding: 21px 32px;
    border: 1px solid #d5d7de;
    border-radius: 8px;
  }

  .buttonBackground {
    &--white {
        background-color: #fff !important;
    }
  }
`;