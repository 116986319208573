import styled from 'styled-components';

export const StyledAnnouncement = styled.div`

    width: 100%;
    display:  ${({ isShow }) => isShow ? 'flex' : 'none'};
    align-items: center;
    justify-content: space-between;
    padding: 11px 18px;
    color:${({ theme }) => theme.colors.text.text_08};
    background: ${({ theme }) => theme.colors.support.middle};

    svg{
        margin-right: unset !important;
    }

    @media (max-width: 1024px) {
        padding: 13px 18px;
        align-items: flex-start;
    }

    >svg{
        cursor: pointer;
        margin-left: 10px;
        @media (max-width: 1024px) {
            flex-shrink: 0;
            margin-top: 5px;
        }
    }

    .content{
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 1024px){
            align-items: flex-start;
        }

        >svg{
            font-size: 25px;
			color: ${({ theme }) => theme.colors.support.warning};
			@media (max-width: 1024px) {
                flex-shrink: 0;
				font-size: 25px;
			}
        }
    }

    .textBox{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        margin-left: 10px;

        @media (max-width: 1024px) {
            display: inline-block;
        }

        .title{
            font-weight: bold;
            flex-shrink: 0;
        }

        .text{

        }

    }
`;