import React, { useContext, useMemo, useEffect } from 'react';
import { StyledAnnouncement } from './Announcement.style';
import { default as firebaseRemoteConfigProvider } from 'providers/firebaseRemoteConfigProvider.js';
import { MegaphoneSolid, XmarkOutline } from '@onedesign/icon';

export const Announcement = ({ className, setIsShowHint }) => {

  const { remoteConfigData, RemoteConfigDataProviderChange } = useContext(firebaseRemoteConfigProvider.firebaseRemoteConfigProviderContext);
  const { announcement } = remoteConfigData;

  const configData = useMemo(() => {
    if (!announcement || JSON.stringify(announcement) === '{}') return;
    return announcement && JSON.parse(announcement?._value);
  }, [announcement]);

  return (
    <StyledAnnouncement className={className} data-testid="Announcement" isShow={configData?.isShow}>
      <div className='content'>
        <MegaphoneSolid/>
        <div className='textBox'>
          <span className='title'>
            {configData?.title}：
          </span>
          <span className='text'>
            {configData?.text}
          </span>
        </div>
      </div>
      <XmarkOutline onClick={() => {setIsShowHint(false);}} />
    </StyledAnnouncement>
  );
};