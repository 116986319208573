import { Api } from '@oneclass/ui-components';

// 取得 單一學制科目中 所有課本/知識向度
export const postGetBook = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Query/GetBook`, payload);
  return response;
};

// 查詢 題目類型
export const postGetQuestionBucket = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Query/GetQuestionBucket`, payload);
  return response;
};

// 取得 題目
export const postGetQuestion = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Query/GetQuestions`, { ...payload, isEncrypt: true });
  return response;
};

// for 知識向度命題 , 取得範圍
export const postGetScopes = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Query/GetScopes`, payload);
  return response;
};

// for 知識向度命題 , 取得題目
export const postGetQuestionFromKnowledge = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Query/GetScopeQuestions`, payload);
  return response;
};

// 取得範圍 （電腦、手動、知識點 共用
export const postRangeSelection = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Query/RangeSelection`, payload);
  return response;
};

//取得無限命題的內容
export const postGetDojos = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Query/GetDojos`, payload);
  return response;
};
