import styled from 'styled-components';
import { variant, space } from 'styled-system';
import { ButtonProps } from './Button';

export const StyledButton = styled.div.attrs((props) => ({
  className: props.className,
}))<ButtonProps>`
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ type }) => type !== 'default' ? 'unset !important' : '#fff'};
  border-style: ${({ type }) => type};
  border-color:${({ type, theme }) => type !== 'default' ? `${theme.colors.ui.ui_04} !important` : '#fff'};
  border-width: 1px;
  color:${({ textcolor }) => `${textcolor} !important` || '#000'};
  padding: 9px 16px;
  border-radius: ${({ borderRadius }) => borderRadius || '4px'};
  cursor:${({ disabled, isLoading }) => disabled || isLoading ? 'not-allowed' : 'pointer'} ;
  transition: 0.5s;
  opacity:${({ isLoading }) => isLoading ? '0.8' : '1'};

  ${space}
    ${variant({
    variants: {
      brand1: {
        color: 'text.white',
        backgroundColor: 'scale.$brand01.$brand01_10',
        borderColor: 'scale.$brand01.$brand01_10',
      },
      brand2: {
        color: 'text.white',
        backgroundColor: 'scale.$brand02.$brand02_10',
        borderColor: 'scale.$brand02.$brand02_10',
      },
      ui01: {
        color: 'text.black',
        backgroundColor: 'ui.ui_01',
        borderColor: 'ui.ui_01',
      },
      ui02: {
        color: 'text.text_04',
        backgroundColor: 'ui.ui_02',
        borderColor: 'ui.ui_02',
      },
      ui02_2: {
        color: 'text.text_08',
        backgroundColor: 'ui.ui_02',
        borderColor: 'ui.ui_02',
      },
      error: {
        color: 'text.white',
        backgroundColor: 'support.error',
        borderColor: 'support.error',
      },
      primary2: {
        color: 'text.white',
        backgroundColor: 'scale.$primary.$primary_02',
        borderColor: 'scale.$primary.$primary_02',
      },
      linearGradient: {
        color: 'colors.brand.brand02',
        background: 'linear-gradient(90deg,#fff, #A0A0AD)',
        borderColor: 'linear-gradient(90deg,#fff, #A0A0AD)',
      }
    }
  })}
  &:hover,
	&:focus,
	&:active{
    opacity: 1;
    ${variant({
    variants: {
      brand1: {
        color: 'text.white',
        backgroundColor: 'scale.$brand01.$brand01_09',
        borderColor: 'scale.$brand01.$brand01_09',
      },
      brand2: {
        color: 'text.white',
        backgroundColor: 'scale.$brand02.$brand02_09',
        borderColor: 'scale.$brand02.$brand02_09',
      },
      ui02_2: {
        color: 'text.text_08',
        backgroundColor: 'ui.ui_01',
        borderColor: 'ui.ui_01',
      },
      error: {
        color: 'text.white',
        backgroundColor: 'support.error',
        borderColor: 'support.error',
        opacity: 0.9
      },
      primary2: {
        color: 'text.white',
        backgroundColor: 'scale.$primary.$primary_02',
        borderColor: 'scale.$primary.$primary_02',
        opacity: 0.9
      },
      linearGradient: {
        color: 'colors.brand.brand02',
        background: 'colors.ui.white',
        borderColor: 'colors.ui.white',
      }
    }
  })
}
  }

  svg{
    margin-right:8px;
  }
  
  .spinner{
    -webkit-animation: rotation 1.35s linear infinite;
    animation: rotation 1.35s linear infinite;
    ${variant({
    variants: {
      brand1: {
        stroke: 'text.white'
      },
      brand2: {
        stroke: 'text.white'
      },
      ui01: {
        stroke: 'text.black'
      },
      ui02: {
        stroke: 'text.text_04'
      }
    }
  })}
  }
  @-webkit-keyframes rotation
{
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes rotation
{
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

.circle
{
  stroke-dasharray: 180;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-animation: turn 1.35s ease-in-out infinite;
  animation: turn 1.35s ease-in-out infinite;
}

@-webkit-keyframes turn
{
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes turn
{
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}
`;
