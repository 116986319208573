import { notification } from 'antd';
import { theme as onedesignTheme } from '@oneclass/onedesign';

const notificationStyle = {
  success: {
    backgroundColor: onedesignTheme.colors.support.success,
  },
  error: {
    backgroundColor: onedesignTheme.colors.support.error,
  },
  warning: {
    backgroundColor: onedesignTheme.colors.support.warning,
  },
};

const openNotificationWithIcon = (type, desc) => {
  notification[type]({
    message: desc,
    style: notificationStyle[type],
    className: 'newNotification'
  });
};

export default openNotificationWithIcon;
