import styled from 'styled-components';

export const StyledMobileItem = styled.div`
  padding: 16px 24px;
  svg{
    margin-right: unset;
  }
  .heartIcon{
    font-size: 20px;
    color: #F6564B !important;
  }
  &:not(:last-child){
    border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
  }
  >div:nth-child(1){
    font-size: 16px;
    margin-bottom: 4px;
    color: ${({ theme }) => theme.colors.text.text_10};
  }
  >div:nth-child(2){
    font-size: 14px;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.text.text_07};
  }
  >div:nth-child(3){
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.text.text_05};
  }
`;

export const StyledSearchPaperListPage = styled.div`
  width: 960px;
  margin: auto;
  .tableEmpty{
    height: 373px;
    width: 960px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    >div:nth-child(1){
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      >span:nth-child(1){
        color: ${({ theme }) => theme.colors.text.text_06};
        font-size: 20px;
        margin-bottom: 16px;
      }
      >span:nth-child(2){
        color: ${({ theme }) => theme.colors.text.text_05};
        font-size: 16px;
      }
    }
    .imageBox{
      width: 288.8px;
      height: 237px;
    }
  }
  .pageBlock{
        padding:34px 0 56px;
  }
  .skeletonWidth{
    width: 90%;
  }
  @media (max-width: 1024px) {
    width: 100%;
    min-height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh, 1vh) * 100);

    .mobileSearchHeader{
      display: flex;
      padding: 17px 10px;
      align-items: center;
      >svg:nth-child(1){
        font-size: 24px;
        color: ${({ theme }) => theme.colors.ui.ui_04};
        margin-right: 24px;
      }
    }
    .filterTitle{
      font-size: 18px;
      display: flex;
      align-items: center;
      >svg{
        font-size: 14px;
        margin-left: 13px;
      }
    }
    .searchBarBox{
      padding: 0 16px;
      position: relative;
      margin-bottom: 28px;
      svg{
        margin-right: unset;
      }
    }
    .filterBox{
      padding: 10px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgb(250, 250, 250);
      svg{
        font-size: 24px;
        margin-right: 0px !important;
        color: ${({ theme }) => theme.colors.ui.ui_05};
      }
    }
    .remindText{
      position: absolute;
      right: 16px;
      top: 35px;
      font-size: 11px;
      color: ${({ theme }) => theme.colors.text.text_05};
    }
    .remindText.active{
      color: #FF9800 !important;
    }
    .amount{
      color: ${({ theme }) => theme.colors.text.text_05};
    }
    .tableEmpty{
      flex-direction: column;
      width: 100%;
      height: unset;
      >div:nth-child(1){
        margin: 10% 0 10% 0;
        align-items: center;
      }
      >div:nth-child(2){
        margin: 0 auto;
        width: 95%;
        height: unset;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    // 抽屜共同調整
    .ant-drawer-title{
      font-size: 20px;
    }

    // －－－－ 條件搜尋 抽屜 －－－－
    #searchParamsDrawer{
      .selectFieldBox{
        margin-bottom: 32px;
      }
      .title{
        font-size: 16px;
        margin-bottom: 16px;
        color: ${({ theme }) => theme.colors.text.text_10};
      }
      .subTitle{
        font-size: 14px;
        margin-bottom: 8px;
        color: ${({ theme }) => theme.colors.text.text_06};
      }
      .ant-drawer-content-wrapper{
        height: unset !important;
      }
      .ant-drawer-content{
        overflow: hidden;
        border-radius:12px 12px 0 0;
        height: 97vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 97);
      }
      .ant-drawer-body{
        padding: 16px 24px 16px !important;
        height: calc(calc(var(--vh, 1vh) * 97) - 54px - 84px) !important;
        overflow-y: auto;
      }
      .ant-radio-group.radioList{
        width: 100%;
        .ant-radio-button-wrapper{
          width: calc((92%)/3) !important;
          margin-bottom: 4%;
          padding: 8px 9px;
          margin-left: 0 !important;
          margin-right: 2.66% !important;
        }
        .longerRadio{
          width: calc((92%)/2) !important;
        }
      }
    }
    // 篩選 抽屜
    #filterDrawer{
      .title{
        font-size: 16px;
        margin-bottom: 16px;
        color: ${({ theme }) => theme.colors.text.text_10};
      }
      .clearAllFilterBtn{
          position: absolute;
          right: 20px;
          top: 16px;
          font-size: 14px;
          font-weight: normal;
          color:${({ theme }) => theme.colors.text.text_04};
      }
      .clearAllFilterBtn.active{
          color: ${({ theme }) => theme.colors.brand.brand03};
      }
      .ant-drawer-content-wrapper{
        height: unset !important;
      }
      .ant-drawer-content{
        overflow: hidden;
        border-radius:12px 12px 0 0;
        height: 97vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 97);
      }
      .ant-drawer-body{
        padding: 16px 24px 16px !important;
        height: calc(calc(var(--vh, 1vh) * 97) - 54px - 84px) !important;
        overflow-y: auto;
      }
      .ant-radio-group.radioList{
        width: 100%;
        .ant-radio-button-wrapper{
          width: calc((92%)/3) !important;
          margin-bottom: 4%;
          padding: 8px 9px;
          margin-left: 0 !important;
          margin-right: 2.66% !important;
        }
        .longerRadio{
          width: calc((92%)/2) !important;
        }
      }
    }
  }
`;

export const StyledHeader = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
  margin-bottom: 32px;

  .title{
    font-size: 24px;
    padding: 4px 0 0 8px;
    font-weight:500;
    margin-bottom: 48px;
  }
  .searchBarBox{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .searchBar{
    display: flex;
    align-items: center;
    position: relative;
    >span{
      width: 396px;
      margin-right: 24px;
    }
  }
  .remindText{
    position: absolute;
    right: 150px;
    top: 35px;
    font-size: 11px;
		color: ${({ theme }) => theme.colors.text.text_05};
  }
  .remindText.active{
    color: #FF9800 !important;
  }
  .searchBtn{
    height: 32px;
    width: 128px;
    svg{
      font-size: 18px;
    }
  }
`;

export const StyleContentBox = styled.div`
  .amount{
    padding: 4px 0 0 8px;
		color: ${({ theme }) => theme.colors.text.text_05};
  }
  .tableZone{
    > div > div >div{
        padding:12px 0 12px 16px;
        box-sizing: border-box;
    }
    >div:nth-child(2){
      max-height: none !important;
    }
  }
  .selectBox{
    width: 106px;
    margin: 16px 0 ;
  }

  .count{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 16px 12px 0px !important; 
  }
  .previewBtn{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .likeBtn{
    width: 90%;
    >button{
      color: ${({ theme }) => theme.colors.text.text_06};
    }
    >button:focus{
      border: 1px solid #F6564B !important;
    }
  }
  .likeBtn:hover{
    >button{
      background-color: #FEF2F0 !important;
      border: 1px solid #F6564B;
      color: black;
    }
  }
  .likeBtn.active{
    >button{
      background-color: #FEF2F0 !important;
      border: 1px solid #F6564B;
      color: black;
    }
  }
  .heartIcon{
    color: #F6564B !important;
  }
  .notYetSearchBox{
    display: flex;
    >div{
      width: 50%;
    }
    >div:nth-child(1){
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 100px;
      height: 436px;
      >span{
        color: ${({ theme }) => theme.colors.text.text_08};

      }
      >span:nth-child(1){
        font-size: 30px;
        margin-bottom: 10px;
      }
      >span:nth-child(2),>span:nth-child(3){
        font-size: 16px;
      }
    }
  }
`;
