// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getRemoteConfig, fetchAndActivate, getAll } from 'firebase/remote-config';
import { openNotificationWithIcon } from '@oneclass/ui-components';

const envConfig = require('firebaseConfig.json');

const {
  ONE_PAPER_API_KEY,
  ONE_PAPER_AUTH_DOMAIN,
  ONE_PAPER_PROJECT_ID,
  ONE_PAPER_STORAGE_BUCKET,
  ONE_PAPER_MESSAGING_SENDER_ID,
  ONE_PAPER_APP_ID,
  ONE_PAPER_MEASUREMENT_ID,
} = envConfig[process.env.REACT_APP_NODE_ENV];

const firebaseConfig = {
  apiKey: ONE_PAPER_API_KEY,
  authDomain: ONE_PAPER_AUTH_DOMAIN,
  projectId: ONE_PAPER_PROJECT_ID,
  storageBucket: ONE_PAPER_STORAGE_BUCKET,
  messagingSenderId: ONE_PAPER_MESSAGING_SENDER_ID,
  appId: ONE_PAPER_APP_ID,
  measurementId: ONE_PAPER_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 10; // 本機 cache 時間長短（時間內將不會重複打api)

// 取得 全部 RemoteConfig 資料
export const getAllRemoteConfig = async() => {
  const response = await fetchAndActivate(remoteConfig).then((res) => {
    const data = getAll(remoteConfig);
    return data;
  }).catch((err) => {
    openNotificationWithIcon('error', err);
  });
  return response;
};

