import styled from 'styled-components';

export const StyledExamPaperListPage = styled.div.attrs(props => ({
  className: props.className
}))`
    .Tutoring{
      margin-top: 44px;
      padding-top: unset !important;
    }
    .ant-tabs-top > .ant-tabs-nav{
        margin: 0 0 0 0;
    }
    .ant-tabs-tab + .ant-tabs-tab{
      margin: 0 0 0 0px !important;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap{
      padding: 0 16px;
    }
    .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
      padding: 16px 16px;
      font-size: 16px;
    }
`;

