import styled from 'styled-components';
import { Popover } from 'antd';
import { PopoverProps } from './Popover';

export const StyledPopover = styled(Popover)<PopoverProps>`
     .ant-popover  {
       
       .ant-popover-content {

         .ant-popover-arrow {
           display: ${({ arrow }) => arrow ? 'block' : 'none'};
           border-color: #232323 !important;
         }

         .ant-popover-inner {
            background: #232323;
            border-radius: 12px;
            overflow: hidden;
            .ant-popover-title {
              padding: 12px;
              text-align: center;
              color: #fff;
              border-bottom: none;
            }

            .ant-popover-inner-content {
              color: #fff;
              padding: 0;
            }

          }

      }
    }
    
`;