import styled from 'styled-components';
import { space } from 'styled-system';
export const UiFlexBox = styled.div`
	display: flex;
	flex: 1;
	padding: 8px;
	flex-direction: column;
	overflow: auto;

    padding: 32px;
    @media (max-width: 1024px) {
        padding: 16px 22px;
    }

    .label{
        margin-bottom: 16px;
		font-size: 18px;
		font-weight: normal;
		display: flex;
		align-items: center;
        &>div{
            display: flex;
            margin-left: 24px;
        }
    }
    ${space}
`;
