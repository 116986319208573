import styled from 'styled-components';

const commonButton = `
  padding: 8px 16px;

  > svg {
    font-size: 16px;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const outlinedButton = `
  color: #121232;
  border: 1px solid #D5D7DE;
`;

export const StyledTestDetailPage = styled.div`
  width: 1024px;
  margin: auto;
  line-height: 1.5;
`;

export const StyledBackToList = styled.div`
  display: inline-flex;
  margin-bottom: 28px;
  color: #8B90A0;
  line-height: 24px;
  align-items: center;
  cursor: pointer;
`;

export const StyledTestInfo = styled.div`
  margin-bottom: 38px;
  padding: 32px;
  padding-top: 0;
  border-bottom: 1px solid #D5D7DE;

  .pageTitle {
    margin-bottom: 8px;
    color: #EC7963;
    font-size: 18px;
    font-weight: 500;
  }

  .testTitle {
    display: flex;
    margin-bottom: 19px;
    justify-content: space-between;
    align-items: center;

    &__name {
      color: #454B5C;
      font-size: 24px;
      font-weight: 500;
    }

    &__actions {
      display: flex;

      > * {
        ${commonButton}
        ${outlinedButton}
      }
    }
  }

  .testInfo {
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      color: #242C3F;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &__label {
      margin-right: 24px;
      color: #8B90A0;
    }

    &__value {

    }
  }
`;

export const StyledTestList = styled.div`
  padding: 0 32px;

  .list {
    &__header {
      display: flex;
      margin-bottom: 24px;
      justify-content: space-between;
      align-items: center;

      &__title {
        font-size: 20px;
        font-weight: 500;
      }

      &__actions {
        display: flex;

        > * {
          ${commonButton}
        }
      }
    }

    &__table {
      .button {
        ${commonButton}
        ${outlinedButton}
      }

      &__header {
        > * > * {
          padding: 17px 16px;
        }
      }

      &__noData {
        margin: 50px auto;
        color: #8B90A0;
      }
    }
  }
`;