import styled from 'styled-components';

export const StyledStatusLabel = styled.div.attrs(props => ({
  className: props.className
}))`
  display: inline;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 4px;

  &.processing, &.new {
      background: #FEF2F0;
      color: #EC7963;
  }
  &.preparing {
      background: #F2F4F8;
      color: #242C3F;
  }
  &.finished {
      background: #EEF5F6;
      color: #4C99A5;
  }
  &.new {
      margin-right: 6px;
      padding: 2px 8px;
  }
`;