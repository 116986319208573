import styled from 'styled-components';

export const StyledTestCodeModalContent = styled.div.attrs(props => ({
  className: props.className
}))`
.testModalContent{
    svg{
        margin-right:0px;
    }
    .testModalLinkBox{
        margin-bottom: 24px;
        .endTime{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 16px;
            >span{
                font-size: 18px;
                color:${({ theme }) => theme.colors.text.text_06};
                @media (max-width: 1024px) {
                    font-size: 14px;
                }
            }
            >div{
                font-size: 24px;
                color:${({ theme }) => theme.colors.text.text_10};
                @media (max-width: 1024px) {
                    font-size: 18px;
                }
            }
        }
        .testModalLinkTitle{
            text-align: center;
            font-size: 24px;
            color:${({ theme }) => theme.colors.text.text_10};
            margin-bottom: 16px;
            font-weight: 400;
            @media (max-width: 1024px) {
                display: none;
            }
        }
        .testModalLinkText{
            text-align: left;
            font-size: 18px;
            color:${({ theme }) => theme.colors.text.text_10};
            margin: 24px 0;
            list-style-type: decimal;
            padding-left: 25px;
            > li:not(:last-child){
                margin-bottom: 4px;
            }
            @media (max-width: 1024px) {
                display: none;
            }
        }
        .testModalLink{
            position: relative;
            padding:24px;
            background-color: ${({ theme }) => theme.colors.ui.ui_02};
            display: flex;
            align-items: center;
            .testCode{
                font-size: 48px;
                color:${({ theme }) => theme.colors.text.text_10};
                width: 100%;
                text-align: center;
                span{
                    &:last-child{
                        padding-left: 8px;
                    }
                }
            }
            .copyImgBox{
                position: absolute;
                right: 2.5%;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (max-width: 1024px) {
                    bottom: 10%;
                }
            }
            .copyHint{
                line-height: 21px;
                display: flex;
                align-items: center;
                .copyImg{
                    position: relative;
                    width: 17px;
                    height: 17px;
                    background: #fff;
                    border-radius: 50%;
                    margin-right: 4px;
                   svg{
                       position: absolute;
                       top: -2px;
                        left: -2px;
                        color: ${({ theme }) => theme.colors.support.success};
                        /* border-radius: 50%;
                        background-color: #fff; */
                    } 
                }
                
            }
            .testModalLinkData{
                display: none;
                font-size: 24px;
                color:${({ theme }) => theme.colors.text.text_10};
                width: 95%;
                margin-right:2.5%;
                border:none;
                overflow:scroll;
            }
            svg{
                font-size: 21px;
                color:${({ theme }) => theme.colors.ui.ui_05};
                
            }
        }
    }
    .testModalManualBox{
        padding: 24px 32px;
        border: 1px solid #E4E7EC;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 14px;
        .testModalManualList{
            padding-left: 20px;
            list-style-type: decimal;
            > li:not(:last-child){
                margin-bottom: 4px;
            }
            a {
                color: ${({ theme }) => theme.colors.brand.brand03};
                text-decoration:underline;
                &:hover{
                    color: ${({ theme }) => theme.colors.scale.$brand03.$brand03_12};
                }
            }
        }
        .testModalManualButton{
            >div{
                display: inline-flex;
            }
        }
        .copyHint{
            line-height: 21px;
            display: flex;
            align-items: center;
            .copyImg{
                position: relative;
                width: 17px;
                height: 17px;
                background: #fff;
                border-radius: 50%;
                margin-right: 4px;
                svg{
                    position: absolute;
                    top: -2px;
                    left: -2px;
                    color: ${({ theme }) => theme.colors.support.success};
                    /* border-radius: 50%;
                    background-color: #fff; */
                } 
            }
            
        }
        svg{
            font-size: 21px;
        }
        .copyTextBtn {
            svg{
                margin-right: 10px;
            }
        }
        @media (max-width: 1024px) {
            padding: 20px 16px;
        }
    }
    @media (max-width: 1024px) {
        padding: 0 24px;

        .testCode{
            font-size: 24px !important;
        }
    }
}
`;