import styled from 'styled-components';

export const UiLoginPage = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	
	.wrapper {
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		position: relative;

		.content {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 24px 64px;
			flex-direction: column;
			.loginBtn{
				width: 100%;
				justify-content: center;
			}
			.iconBox {
				i {
					font-size: 72px;
					color: #52c41a;
				}
			}

			.subTitle {
				font-size: 20px;
				color: #98989e;
			}

			.title {
				padding: 32px;
			}

			.largeTitle {
				font-size: 32px;
			}

			.prompt {
				padding: 3px 0;
				color: #ff4d4f;
			}

			.btnBox {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 10px;
				width: 100%;

				& > button {
					margin: 0 5px;
					width: max-content;
				}
			}

			.form {
				width: 100%;

				& > * {
					margin-bottom: 20px;
				}
			}

			.btn {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				margin: 10px auto;
				padding: 5px 0;
				width: 100%;
				height: unset;
				font-size: 1.2rem;
				color: #fff;
				background-color: #3f8ae0;
				border-color: #3f8ae0;
				border-radius: 3px;
				box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
				text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
				transition: 0.5s;
				cursor: pointer;

				&:hover {
					background: #40a9ff;
					border-color: #40a9ff;
				}
			}

			.links {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 24px;

				a {
					padding: 0 10px;
					color: rgba(0, 0, 0, 0.65);
					transition: 0.5s;
					color: ${({ theme }) => theme.colors.text.text_05};

					&:hover {
						color: ${({ theme }) => theme.colors.text.text_07};
					}
				}

				div {
					color: #98989e;
				}
			}
		}
	}
	.loginImg{
		margin-right: 8px;
	}
	.orTextZone{
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		position: relative;
		padding: 10px 0;
		::after{
			content: '';
			width: 100%;
			height: 1px;
			background-color: ${({ theme }) => theme.colors.text.text_05};
			position: absolute;
			top:50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
		}
		.orText{
			background-color: #fff;
			position: relative;
			z-index: 2;
			padding: 0 8px;
			color:${({ theme }) => theme.colors.text.text_05};
		}
	}
`;
