import styled from 'styled-components';

export const StyledFilterBar = styled.div`
    position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	> *{
		&:not(:last-child){
			margin-right: 8px;
		}
	}
`;

export const StyledStudentExamPaperList = styled.div.attrs(props => ({
  className: props.className
}))`
    width: 928px;
    margin:auto;
    .pageTitle{
        font-size: 18px;
        padding: 4px 0 0 8px;
        font-weight:500;
    }
    .tableEmpty{
        width: 60%;
        margin:50px auto;
        >div{
            padding:4px 0 !important;
            color:${({ theme }) => theme.colors.text.text_05};
        }
        text-align: center;
    }
    .pageBlock{
        padding:34px 0 56px;
    }
    .normal{
        color:${({ theme }) => theme.colors.text.text_05};
    }
    .warning{
        color:${({ theme }) => theme.colors.support.warning};
    }
    .btnTableItem{
        >:first-child{
            margin-right: 8px;
        }
    }
    .tableBody{
        max-height: unset;
    }
    .tableEmpty{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 40px;
        text-align: unset;
        >div{
            padding:4px 0 !important;
            color:${({ theme }) => theme.colors.text.text_05};
        }
        .makeATextPaperBtn{
            margin-top: 32px;
            color: #fff !important;
            padding: 8px 34px !important;
        }
    }
    @media (max-width: 1024px) {

        width: 100%;
        .tableBody{
            min-height: calc(calc(var(--vh, 1vh) * 100) - 160px);
            >div:not(:last-child){
                border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03} !important;
            }
        }
        .filterBtnBox{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 5px 28px;
            background-color: rgb(250, 250, 250);
            height: 44px;
            >div{
                display: flex;
                justify-content: center;
                align-items: center;
            }
            svg{
                font-size: 24px;
                margin-right: 0px !important;
                color: ${({ theme }) => theme.colors.ui.ui_05};
            }
            .active{
                padding: 5px 5px;
                background-color: ${({ theme }) => theme.colors.brand.brand02};
                border-radius: 8px;
                >svg{
                    color: #fff;
                }
            }
        }
        .mobileItem{
            padding: 16px 24px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-bottom: 0px !important;
            >div{
                width: 100%;
            }
            .triangleZone{
                display: none;
            }
            >div:nth-child(1){
                margin-bottom: 8px;
                font-size: 16px;
                color:${({ theme }) => theme.colors.text.text_10};
            }
            >div:nth-child(2){
                margin-bottom: 4px;
                font-size: 14px;
                color:${({ theme }) => theme.colors.text.text_07};
            }
            >div:nth-child(3){
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 12px;
                >div:first-child{
                    color:${({ theme }) => theme.colors.text.text_05};
                }
            }
        }
        .warning{
            padding: 4px 12px;
            background-color: ${({ theme }) => theme.colors.support.middle};
            color:${({ theme }) => theme.colors.support.warning} !important;
            line-height: 12px;
            border-radius: 4px;
        }
        .normal{
            padding: 4px 12px;
            background-color:${({ theme }) => theme.colors.ui.ui_02} ;
            color:${({ theme }) => theme.colors.text.text_06} !important;
            line-height: 12px;
            border-radius: 4px;
        }
        .ant-modal-wrap{
            display: flex;
            align-items: flex-end;
        }
        .ant-modal{
            max-width: 100%;
            margin: 0 0;
            padding: 0 0;
            top: 0;
        }
        .ant-modal-content{
            border-radius: 12px 12px 0 0;
        }
        .ant-modal-body{
            padding: 0 24px 20px 24px;
        }
        .ant-modal-header{
            display: flex;
            justify-content:center;
            align-items:center;
            padding: 21px 40px 21px;
        }
        .ant-modal-close-x{
            display: flex;
            justify-content:center;
            align-items:center;
            width: 50px;
            height:63px;
        }
        #detailModal{
            .detailModalContent{
                margin-bottom: 70px;
                >div{
                    margin-bottom: 16px;
                    display: flex;
                    >div:nth-child(1){
                        text-align: right;
                        margin-right: 20px;
                        color:${({ theme }) => theme.colors.text.text_06};
                        width: 60px;
                        flex-shrink: 0;
                    }
                    >div:nth-child(2){
                        color:${({ theme }) => theme.colors.text.text_07};
                    }
                }
                .warning,.normal{
                    background-color: unset;
                    padding: 0 0;
                    line-height: unset;
                }
            }
            .detailModalBtnBox{
                >div{
                    border-radius: 8px;
                    &:not(:last-child){
                        margin-bottom: 12px;
                    }
                }
            }
        }
        #filterDrawer{
            // 篩選彈窗
            .clearAllFilterBtn{
                position: absolute;
                right: 20px;
                top: 21px;
                font-size: 14px;
                font-weight: normal;
                color:${({ theme }) => theme.colors.text.text_04};
            }
            .clearAllFilterBtn.active{
                color: ${({ theme }) => theme.colors.brand.brand03};
            }
            .ant-modal-close{
                right: unset !important;
                left: 0 !important;
            }
            .ant-modal-body{
                height: calc(calc(var(--vh, 1vh) * 100) - 64px - 97px);
                overflow: auto;
            }
            .ant-modal-footer{
                padding: 10px 24px 42px 24px;
                background-color: #fff;
                box-shadow: rgb(0 0 0 / 10%) 0px -1px 4px;
                border-radius: 0px;
                >div{
                    width: 100%;
                    >div{
                        width: 100%;
                        margin-right: 0px;
                    }
                }
            }
            .filterDrawerContent{
                margin-bottom: 24px;
                .title{
                    font-size: 16px;
                    margin-bottom: 16px;
                }
                .subTitle{
                    margin-bottom: 8px;
                    color:${({ theme }) => theme.colors.text.text_06};
                }
                &:not(:last-child){
                    border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
                }
                .ant-radio-group{
                    padding-bottom: 24px;
                    >label{
                        margin-left: 0px !important;
                        margin: 0px 8px 8px 0;
                    }
                }
            }
        }
    }
`;