import PropTypes from 'prop-types';
import RouteLeaveAlert from 'components/RouteLeaveAlert/RouteLeaveAlert';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { CreateExamPaperParamsProvider } from '@oneclass/ui-components';

const PAGES_NEED_CONFIRM = [
  '/computerQuestion',
  '/manualProposition',
  '/equationDojo',
];

export const routeLeaveContext = createContext(() => {});

export const RouteLeaveProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { createExamPaperParams } = useContext(
    CreateExamPaperParamsProvider.createExamPaperContext
  );
  const [isModalShow, setIsModalShow] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLast, setIsLast] = useState(false);

  const shouldDetectRouteLeave = useMemo(
    () =>
      PAGES_NEED_CONFIRM.includes(location.pathname) &&
      (createExamPaperParams?.edu || createExamPaperParams?.subject),
    [location.pathname, createExamPaperParams]
  );

  const openModal = (targetLocation) => {
    setIsModalShow(true);
    setNextLocation(targetLocation);
  };

  const closeModal = () => {
    setIsModalShow(false);
  };

  const confirmLeave = () => {
    setIsModalShow(false);
    if (nextLocation) {
      setIsConfirmed(true);
    }
  };

  const handleBlockedNavigation = (targetLocation) => {
    if (
      !isConfirmed &&
      targetLocation.pathname !== '/login' &&
      !targetLocation?.state?.completed &&
      !PAGES_NEED_CONFIRM.includes(targetLocation.pathname) &&
      !isLast
    ) {
      openModal(targetLocation);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (isConfirmed) {
      setIsConfirmed(false);
      history.push(nextLocation.pathname);
    }
  }, [isConfirmed]);

  const value = { setIsLast };

  return (
    <routeLeaveContext.Provider value={value}>
      {children}
      <Prompt when={shouldDetectRouteLeave} message={handleBlockedNavigation} />
      {isModalShow && (
        <RouteLeaveAlert
          show={isModalShow}
          onLeave={confirmLeave}
          onStay={closeModal}
        />
      )}
    </routeLeaveContext.Provider>
  );
};

RouteLeaveProvider.propTypes = {
  children: PropTypes.node,
};
