import { Api } from '@oneclass/ui-components';
// import { stringify } from 'query-string';

// 取得錯題庫 學制科目 （僅有回傳有錯題的範圍）
export const getFixSelection = async () => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Fix/Selection`);
  return response;
};

// 取得錯題列表
export const getFixQuestionList = async (payload) => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Fix/${payload.eduSubject}?year=${payload.year}`, payload);
  return response;
};

// 移除 錯題
export const putFixUnderstand = async (payload) => {
  const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Fix/${payload.eduSubject}/Understand?ID=${payload.ID}`, payload);
  return response;
};

// 建立測驗
export const postFixPractice = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Fix/Practice `, payload);
  return response;
};
