// *
// 隨時監聽判斷目前的 螢幕寬度，來控制頁面要顯示，桌機還是手機版面。
//*
import React, { createContext, useState, useEffect, useMemo } from 'react';
export const deviceProviderContext = createContext(() => {});
export const Provider = ({ children }) => {

  const getDeviceType = (() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)
    ) {
      return 'Mobile';
    } else {
      return 'Desktop';
    }
  });

  // 搜尋參數狀態
  const [deviceData, setDeviceData] = useState({
    screenWidth: document.body.clientWidth,
    isDesktop: document.body.clientWidth > 1024,
    device: getDeviceType(),
  });

  const deviceDataProviderChange = (key, value) => {

    if (key === 'screenWidth') {

      setDeviceData({
        ...deviceData,
        [key]: value,
        isDesktop: document.body.clientWidth > 1024,
        device: getDeviceType(),
      });
    } else {
      setDeviceData({
        ...deviceData,
        [key]: value,
      });
    }
  };

  const value = { deviceData, deviceDataProviderChange };

  return (
    <deviceProviderContext.Provider value={value}>
      {children}
    </deviceProviderContext.Provider>
  );
};

const deviceProvider = {};
deviceProvider.Provider = Provider;
deviceProvider.deviceProviderContext = deviceProviderContext;

export default deviceProvider;


