import { Api } from '@oneclass/ui-components';
// import { stringify } from 'query-string';

export const postCreatePractice = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Practice/Create`, payload);
  return response;
};

export const getQueryPracticeList = async () => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Practice/Query`);
  return response;
};

export const postPracticeStart = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Practice/Start`, payload);
  return response;
};
