// *
// 存放 FireBase RemoteConfig 資料。
//*
import React, { createContext, useState, useEffect } from 'react';
export const firebaseRemoteConfigProviderContext = createContext(() => {});
export const Provider = ({ children }) => {

  const [remoteConfigData, setRemoteConfigData] = useState({
    announcement: {}, // 頁面上方 提示公告。
    maintenancePage: {}, // 維護頁面。
  });

  const RemoteConfigDataProviderChange = (value) => {
    setRemoteConfigData(value);
  };

  const value = { remoteConfigData, RemoteConfigDataProviderChange };

  return (
    <firebaseRemoteConfigProviderContext.Provider value={value}>
      {children}
    </firebaseRemoteConfigProviderContext.Provider>
  );
};

const firebaseRemoteConfigProvider = {};
firebaseRemoteConfigProvider.Provider = Provider;
firebaseRemoteConfigProvider.firebaseRemoteConfigProviderContext = firebaseRemoteConfigProviderContext;

export default firebaseRemoteConfigProvider;


