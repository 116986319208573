export const useBitrix = () => {
  const insert = () => {
    (function(w, d, u) {
      if (document.getElementsByClassName('b24-widget-button-wrapper')?.[0]) {
        return null;
      } else {
        var s = d.createElement('script');s.async = true;s.src = u + '?' + (Date.now() / 60000 | 0);
        var h = d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s, h);
      }
    })(window, document, 'https://cdn.bitrix24.com/b17048917/crm/site_button/loader_15_nudfls.js');
  };

  const showButton = () => {
    const isShow = document.getElementsByClassName('b24-widget-button-wrapper')?.[0];

    if (isShow) {
      isShow.style.display = 'block';
    }
  };

  const hideButton = () => {
    const isShow = document.getElementsByClassName('b24-widget-button-wrapper')?.[0];

    if (isShow) {
      isShow.style.display = 'none';
    }
  };

  return { insert, showButton, hideButton };
};