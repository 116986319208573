import styled from 'styled-components';
import { Modal } from '@oneclass/ui-components';

export const StyledTestListContent = styled.div`
  .loadingContent{
      height: calc(100vh - 169px);
  }
`;

export const StyledShareModal = styled(Modal)`
  .ant-modal-title{
      justify-content: center;
  }
  .ant-tabs-tab-btn{
      color:${({ theme }) => theme.colors.text.text_05};
  }
  .ant-tabs{
    padding-top:24px;
  }
  .ant-tabs-nav{
      margin-bottom: 48px !important;
  }
  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
      padding: 16px 32px !important;
  }

  @media (max-width: 1024px) {
    .modalContent{
        display: flex;
        flex-direction: column;
    }
    .ant-modal{
        position: absolute;
        bottom: 0;
        max-width:100%;
        padding: 0 0;
        margin: 0 0;
    }
    .ant-modal-content{
        position: absolute;
        bottom: 0;
        border-radius: 12px 12px 0 0;
        width: 100vw;
        height: 97vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 97);
        .ant-modal-close{
            right: 0;
        }
    }
    .ant-modal-header{
        display: flex;
        justify-content:center;
        align-items:center;
        padding: 21px 40px 21px;
    }
    .ant-modal-close-x{
        display: flex;
        justify-content:center;
        align-items:center;
        width: 50px;
        height:63px;
    }
    .ant-modal-body{
        padding: 16px 24px 16px;
        background-color: #fff;
        height: calc(calc(var(--vh, 1vh) * 97) - 64px);
        overflow: auto;
    }
    // ===== //
    .ant-tabs{
        padding-top: unset;
    }
    .ant-tabs-tab-btn{
        font-size: 14px !important;
    }
    .ant-tabs-nav{
        margin-bottom: 16px !important;
    }
    .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
        padding: 16px 32px !important;
    }
    .ant-modal-body{
        padding: 0px 0px 24px 0px !important;
    }
    .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab{
        padding: 5px 15px !important;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap{
        padding:  0 10px;
    }
  }
`;

export const StyledHintModal = styled(Modal)`
  .ant-modal{
    width: 600px !important;
    height: 222px !important;
  }
  .hintModalContent{
    color: ${({ theme }) => theme.colors.text.text_06};
  }

  .footerBtn{
    .confirmBtn {
      background-color:  ${({ theme }) => theme.colors.support.error} !important;
      color: white !important;
    }
  }

  @media (max-width: 1024px) {
    .ant-modal{
      width: unset !important;
      height: unset !important;
      position: absolute;
      bottom: 0;
      max-width:100%;
      padding: 0 0;
      margin: 0 0;
    }
    .ant-modal-content{
        position: absolute;
        bottom: 0;
        border-radius: 12px 12px 0 0;
        width: 100vw;
        height: auto !important;
        .ant-modal-close{
            right: 0;
        }
    }
    .ant-modal-header{
        display: flex;
        justify-content:center;
        align-items:center;
        padding: 21px 40px 21px;
    }
    .ant-modal-close-x{
        display: flex;
        justify-content:center;
        align-items:center;
        width: 50px;
        height:63px;
    }
    .ant-modal-body{
        padding: 16px 24px 16px;
        background-color: #fff;
        height: auto !important;
        overflow: auto;
    }
    .footerBtn{
      width: 100%;
    }
    .footerBtnBox{
      width: 100%;
      flex-direction: column;
      >div{
        width: 100%;
        margin-right: unset !important;
        &:nth-child(1){
          order: 2;
        }
        &:nth-child(2){
          order: 1;
          margin-bottom: 8px;
        }
      }
    }
  }
`;