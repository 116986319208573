export const useTouch = ({ leftToRight, rightToLeft, topToBottom, BottomToTop, justTouch, XGap, YGap }) => {
  // 使用說明：
  //        1. 請在要做 touch動作 的元素上面綁上 onTouchStart 及 onTouchEnd 事件。
  //        2. 將要觸發的作動 放入 對應事件內（上、下、左、右滑動、點擊）。
  //        3. X軸、Y軸 滑動多少距離 觸發。

  let startX;
  let startY;
  let moveEndX;
  let moveEndY;
  let X;
  let Y;

  const cleanData = () => {
    startX = 0;
    startY = 0;
    moveEndX = 0;
    moveEndY = 0;
    X = 0;
    Y = 0 ;
  };

  const onTouchStart = (e) => {
    startX = e.changedTouches[0].pageX;
    startY = e.changedTouches[0].pageY;
  };

  const onTouchEnd = (e) => {
    moveEndX = e.changedTouches[0].pageX;
    moveEndY = e.changedTouches[0].pageY;
    X = moveEndX - startX;
    Y = moveEndY - startY;
    if (!XGap) XGap = 0;
    if (!YGap) YGap = 0;

    if ( Math.abs(X) > Math.abs(Y) && X > XGap ) {
      if (leftToRight) leftToRight();
    }
    else if ( Math.abs(X) > Math.abs(Y) && X < -XGap) {
      if (rightToLeft) rightToLeft();
    } else if (Math.abs(Y) > Math.abs(X) && Y > YGap) {
      if (topToBottom) topToBottom();
    } else if (Math.abs(Y) > Math.abs(X) && Y < -YGap) {
      if (BottomToTop) BottomToTop();
    } else {
      if (justTouch) justTouch();
    }
    cleanData();
  };

  return { onTouchStart, onTouchEnd };
};