import styled from 'styled-components';

export const StyledMyPaperDetailPage = styled.div`
  width: 1024px;
  margin: auto;
  line-height: 1.5;
`;

export const StyledBackToList = styled.div`
  display: inline-flex;
  margin-bottom: 28px;
  color: #8B90A0;
  line-height: 24px;
  align-items: center;
  cursor: pointer;
`;

export const StyledMyPaperDetailContent = styled.div.attrs(props => ({
  className: props.className
}))`
padding: 0 32px;
.title {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.buttonGroup {
    display: flex;
    gap: 10px;

    .btn {
    height: 32px;

    &.outlined {
    color: #121232;
    border: 1px solid #D5D7DE;
  }
  }
}
.lastEditTime {
    display: flex;
    justify-content: flex-end;
    margin: 16px 0;
    font-size: 14px;
    color: #8B90A0;
}
.tableZone{
    > div > div >div{
        padding:12px 0 12px 16px;
        box-sizing: border-box;
    }
}
.tableBtn{
    padding: 4px 12px;
    line-height: 24px;

    &.outlined {
        color: #121232;
        border: 1px solid #D5D7DE;
    }
}
.pageBlock {
  margin: 32px 0
}
`;

export const StyledDetailModal = styled.div.attrs(props => ({
  className: props.className
}))`
#detailModal{
  .modalContent{
        display: flex;
        justify-content: space-between;
        .modalRow{
            flex:1
        }
        .modalInnerTitle{
            font-weight: 500;
            font-size: 16px;
        }
        .modalInnerContent{
            padding:16px 20px;
            .modalInfoRow{
                display: flex;
                color:${({ theme }) => theme.colors.text.text_06};
                &:not(:first-child){
                    margin-top: 16px;
                }
                > div{
                    width: 20%;
                }
                > span{
                    width: 80%;
                    color:${({ theme }) => theme.colors.text.text_07};
                    word-break: break-all;
                }
            }
            .buttonZone{
                display: flex;
                >div{
                    padding: 4px 12px;
                    svg{
                        font-size: 16px;
                    }
                    &:not(:last-child){
                        margin-right: 8px;
                    }
                }
            }
            .modalPublicContent{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .modalPublicContentTitle{
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    svg{
                        font-size: 20px;
                    }
                }
                .modalHint{
                    padding: 4px 28px;
                    font-size: 11px;
                    color:${({ theme }) => theme.colors.text.text_06};
                }
            }
        }
        .copyEditBtnBox{
            display: flex;
            align-items: center;
            >div{
                padding:4px 12px;
                &:not(:last-child){
                    margin-right: 8px;
                }
            }
            svg{
                font-size: 16px;
            }
        }
    }
}
`;