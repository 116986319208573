import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const StyledLoadingMark = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    z-index: 2799;
    opacity: 0.7;
    .loadingIcon{
        z-index: 2800;
    }
`;
export const useLoadingMark = () => {

  const antIcon = <LoadingOutlined style={{ fontSize: 70, color: '#c4c4c4' }} spin />;
  const [isLoading, setIsLoading] = useState(false);

  const renderLoadingMark = () => {
    return (
      isLoading
        ?
        <StyledLoadingMark>
          <Spin className='loadingIcon' indicator={antIcon} />
        </StyledLoadingMark>
        : null
    );
  };
  return { renderLoadingMark, isLoading, setIsLoading };
};
