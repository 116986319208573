import React, { useState } from 'react';
import { XmarkOutline } from '@onedesign/icon';
import { StyledTag } from './Tag.style';

export type TagProps = {
    className?: string;
    children?: React.ReactNode;
    active?: boolean;
    closeable?: boolean;
    clickable?: boolean;
    onClose?: ()=> void;
    onClick?: (active: boolean)=> void;
};

export const Tag = ({
  className,
  children,
  active,
  closeable,
  clickable,
  onClose,
  onClick
}: TagProps) => {
  const [visible, setVisible] = useState(true);

  const onTagClose = () => {
    setVisible(false);
    onClose && onClose();
  };

  const onTagClick = () => {
    onClick && onClick(!!active);
  };

  return (
    <>
      {
        visible ?
          <StyledTag className={className} data-testid="Tag" active={active} onClick={onTagClick} clickable={clickable}>
            {children}
            {
              closeable ? <span onClick={onTagClose} data-testid="Close"><XmarkOutline/></span> : null
            }
          </StyledTag>
          : null
      }
    </>

  );
};


export type CheckableTagProps = {
  className?: string;
  children?: React.ReactNode;
  checked?: boolean;
  onChange?: (checkedValue: boolean)=> void;
}

export const CheckableTag = (props: CheckableTagProps) => {
  const { className, children, checked, onChange } = props;
  const [tagChecked, setTagChecked] = useState(checked);
  const onTagClick = (checked: boolean) => {
    setTagChecked(!checked);
    onChange && onChange(!checked);
  };
  return (
    <Tag className={className} clickable={true} active={tagChecked} onClick={(active => {onTagClick(active);})}>{children}</Tag>
  );
};