import styled from 'styled-components';
import { flexbox, FlexboxProps, layout, LayoutProps, space, SpaceProps, grid, GridProps } from 'styled-system';

export const Box = styled.div<LayoutProps & FlexboxProps & SpaceProps & GridProps>`
    ${layout}
    ${flexbox}
    ${grid}
    ${space}
`;

