import styled from 'styled-components';
import { AvatarProps } from './Avatar';

export const StyledAvatar = styled.div.attrs(props => ({
  className: props.className
}))<AvatarProps>`
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,0.85);
    font-size: 12px;
    line-height: 1.66667;
    list-style: none;
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ccc;
    width: ${({ size }) => size || '28px'};
    height: ${({ size }) => size || '28px'};
    line-height: ${({ size }) => size || '28px'};
    border-radius: 50%;
    
    & > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `;