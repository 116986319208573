import styled from 'styled-components';
import { Modal } from '@oneclass/ui-components';

export const StyledFilterBar = styled.div`
    position: relative;
	display: flex;
	align-items: center;
    justify-content: flex-end;
	flex-wrap: wrap;
	> *{
		&:not(:last-child){
			margin-right: 8px;
		}
	}

    > .toggleButtons {
        display: flex;
        margin-right: auto;
        padding: 4px;
        border: 1px solid rgba(145, 158, 171, 0.12);
        border-radius: 8px;
        column-gap: 4px;

        > .button {
            padding: 8px;
            color: #637381;
            font-size: 14px;
            font-weight: 600;
            line-height: 26px;
            border-radius: 8px;
            cursor: pointer;

            &.selected {
                background-color: #EC796314;
                color: #EC7963
            }
        }
    }
`;


export const StyledPaperPackageListContent = styled.div.attrs(props => ({
  className: props.className
}))`

width: 1024px;
margin:auto;
padding-top:24px;
.pageTitle{
    font-size: 18px;
    padding: 4px 0 0 8px;
    font-weight:500;
}
.tableZone{
    > div > div >div{
        padding:12px 0 12px 16px;
        box-sizing: border-box;
    }
}
.tableEmpty{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
    >div{
        padding:4px 0 !important;
        color:${({ theme }) => theme.colors.text.text_05};
    }
    .makeATextPaperBtn{
        margin-top: 32px;
        color: #fff !important;
        padding: 8px 34px !important;
    }
}
.pageBlock{
    padding:34px 0 56px;
}
.createQuizBtn{
    cursor: pointer;
    width: 32px;
    height: 30px;
    >img{
        margin: 0 4px;
    }
}
.moreDetail{
    >div{
        padding: 9px 10px;
    }
}
#detailModal{
    .modalContent{
        display: flex;
        justify-content: space-between;
        .modalRow{
            flex:1
        }
        .modalInnerTitle{
            font-weight: 500;
            font-size: 16px;
        }
        .modalInnerContent{
            padding:16px 20px;
            .modalInfoRow{
                display: flex;
                color:${({ theme }) => theme.colors.text.text_06};
                &:not(:first-child){
                    margin-top: 16px;
                }
                > div{
                    width: 20%;
                }
                > span{
                    width: 80%;
                    color:${({ theme }) => theme.colors.text.text_07};
                    word-break: break-all;
                }
            }
            .buttonZone{
                display: flex;
                >div{
                    padding: 4px 12px;
                    svg{
                        font-size: 16px;
                    }
                    &:not(:last-child){
                        margin-right: 8px;
                    }
                }
            }
            .modalPublicContent{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .modalPublicContentTitle{
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    svg{
                        font-size: 20px;
                    }
                }
                .modalHint{
                    padding: 4px 28px;
                    font-size: 11px;
                    color:${({ theme }) => theme.colors.text.text_06};
                }
            }
        }
        .copyEditBtnBox{
            display: flex;
            align-items: center;
            >div{
                padding:4px 12px;
                &:not(:last-child){
                    margin-right: 8px;
                }
            }
            svg{
                font-size: 16px;
            }
        }
    }
}
@media (max-width: 1024px) {
    width: 100%;
    padding-top:0px;
    .filterBtnBox{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 5px 28px;
        background-color: rgb(250, 250, 250);
        height: 44px;
        >div{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        svg{
            font-size: 24px;
            margin-right: 0px !important;
            color: ${({ theme }) => theme.colors.ui.ui_05};
        }
        .active{
            padding: 5px 5px;
            background-color: ${({ theme }) => theme.colors.brand.brand02};
            border-radius: 8px;
            >svg{
                color: #fff;
            }
        }
    }
    .pageTitle{
        padding: 9px 24px;
        height: 47px;
    }
    .tableZone{
        min-height: calc(calc(var(--vh, 1vh) * 97) - 221px);
    }
    .tableHeader{
        display: none;
    }
    .tableRow{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px 24px;
        &:not(:last-child){
            border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03} !important;
        }
        >div{
            border-bottom: 0px !important;
            padding: 0 0 !important;
            &:hover{
                background-color:unset !important;
            }
        }
        >div:nth-child(1){
            color:${({ theme }) => theme.colors.text.text_07};
            margin-bottom: 4px;
            font-size: 14px;
        }
        >div:nth-child(2){
            color:${({ theme }) => theme.colors.text.text_10};
            margin-bottom: 8px;
            font-size: 16px;
        }
        >div:nth-child(3){
            color:${({ theme }) => theme.colors.text.text_05};
            font-size: 14px;
        }
    }
    .modalInnerTitle{
        color:${({ theme }) => theme.colors.text.text_10};
    }
    .modalInnerContent{
        padding: 16px 0 !important;
    }
    .modalInfoRow{
        >div{
            text-align: right;
        }
        >span{
            padding-left: 20px;
        }
    }
    .blockDiv{
        border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
    }
    .buttonZone{
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
        >div:not(:last-child){
            margin-right: 0px !important;
            margin-bottom: 12px;
        }
        >div{
            padding: 8px 0 !important;
        }
    }
    .copyEditBtnBox{
        >div{
            width: 50%;
            background-color: #fff;
            color: ${({ theme }) => theme.colors.brand.brand02};
            border: 1px solid #D5D7DE;
            padding: 8px 0 !important;
        }
    }
    .controlBox{
        display: flex;
        flex-direction: column-reverse;
        .modalInnerContent:nth-child(1){
            padding: 0 0 !important;
        }
        .modalInnerContent:nth-child(2){
            padding: 24px 0px 12px 0px !important;
        }
    }
    /* ------ */
    .modalContent{
        display: flex;
        flex-direction: column;
    }
    .ant-modal{
        position: absolute;
        bottom: 0;
        max-width:100%;
        padding: 0 0;
        margin: 0 0;
    }
    .ant-modal-content{
        position: absolute;
        bottom: 0;
        border-radius: 12px 12px 0 0;
        width: 100vw;
        height: 97vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 97);
        .ant-modal-close{
            right: 0;
        }
    }
    .ant-modal-header{
        display: flex;
        justify-content:center;
        align-items:center;
        padding: 21px 40px 21px;
    }
    .ant-modal-close-x{
        display: flex;
        justify-content:center;
        align-items:center;
        width: 50px;
        height:63px;
    }
    .ant-modal-body{
        padding: 16px 24px 16px;
        background-color: #fff;
        height: calc(calc(var(--vh, 1vh) * 97) - 64px);
        overflow: auto;
    }
    // 篩選彈窗
    .clearAllFilterBtn{
        position: absolute;
        right: 20px;
        top: 21px;
        font-size: 14px;
        font-weight: normal;
        color:${({ theme }) => theme.colors.text.text_04};
    }
    .clearAllFilterBtn.active{
        color: ${({ theme }) => theme.colors.brand.brand03};
    }
    #filterDrawer{
        .ant-modal-close{
            left: 0 !important;
            right: unset !important;
        }
        .ant-modal-body{
            height: calc(calc(var(--vh, 1vh) * 97) - 64px - 97px);
            overflow: auto;
        }
        .ant-modal-footer{
            padding: 10px 24px 42px 24px;
            background-color: #fff;
            box-shadow: rgb(0 0 0 / 10%) 0px -1px 4px;
            border-radius: 0px;
            >div{
                width: 100%;
                >div{
                    width: 100%;
                    margin-right: 0px;
                }
            }
        }
        .filterDrawerContent{
            margin-bottom: 24px;
            .title{
                font-size: 16px;
                margin-bottom: 16px;
            }
            .subTitle{
                margin-bottom: 8px;
                color:${({ theme }) => theme.colors.text.text_06};
            }
            &:not(:last-child){
                border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
            }
            .ant-radio-group{
                padding-bottom: 24px;
                >label{
                    margin-left: 0px !important;
                    margin: 0px 8px 8px 0;
                }
            }
        }
    }
  .loadingContent{
      height: calc(100vh - 125px);
  }
}

.outlinedButton {
    padding: 5px 12px;
    border: 1px solid #D5D7DE;
}

.newTag {
    background: #FEF2F0;
    margin-left: 8px;
    padding: 0 8px;
    color: #EC7963;
    border-radius: 4px;
}
`;

export const StyledModal = styled(Modal)`
  .ant-modal{
    width: 600px !important;
    height: 222px !important;
  }
  .ant-modal-header {
    padding: 40px 40px 24px;
  }
  .ant-modal-body {
    padding-bottom: 16px;
  }
  .ant-modal-footer {
    padding-top: 16px;
  }
  .modalContent{
    color: ${({ theme }) => theme.colors.text.text_10} !important;
  }
  .modalContent--sub {
    color: ${({ theme }) => theme.colors.text.text_06};
  }
  .inputContainer {
    display: flex;
    margin-top: 16px;
    column-gap: 16px;

    > .redemptionCodeInput {
        background-color: ${({ theme }) => theme.colors.ui.ui_02};
        display: flex;
        padding-left: 0.6em;
        width: 48px;
        height: 48px;
        font-size: 24px;
        border: none;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        caret-color: transparent;

        &:focus {
            padding-left: calc(0.6em - 2px);
            border: 2px solid #000;
        }
    }
  }

  .footerBtn{
    .cancelBtn {
      background-color: ${({ theme }) => theme.colors.ui.ui_01};
      color:  ${({ theme }) => theme.colors.ui.ui_07};
    }
  }

  @media (max-width: 1024px) {
    .ant-modal{
      width: unset !important;
      height: unset !important;
      position: absolute;
      bottom: 0;
      max-width:100%;
      padding: 0 0;
      margin: 0 0;
    }
    .ant-modal-content{
        position: absolute;
        bottom: 0;
        border-radius: 12px 12px 0 0;
        width: 100vw;
        height: auto !important;
        .ant-modal-close{
            right: 0;
        }
    }
    .ant-modal-header{
        display: flex;
        justify-content:center;
        align-items:center;
        padding: 21px 40px 21px;
    }
    .ant-modal-close-x{
        display: flex;
        justify-content:center;
        align-items:center;
        width: 50px;
        height:63px;
    }
    .ant-modal-body{
        padding: 16px 24px 16px;
        background-color: #fff;
        height: auto !important;
        overflow: auto;
    }
    .footerBtn{
      width: 100%;
    }
    .footerBtnBox{
      width: 100%;
      flex-direction: column;
      >div{
        width: 100%;
        margin-right: unset !important;
        &:nth-child(1){
          order: 2;
        }
        &:nth-child(2){
          order: 1;
          margin-bottom: 8px;
        }
      }
    }
  }
`;