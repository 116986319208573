import styled from 'styled-components';

export const StyledAudioPlayer = styled.div.attrs(props => ({
  className: props.className
}))`
  background: #FFFFFF;
  display: inline-flex;
  margin-bottom: 8px;
  padding: 8px 10px;
  color: #637381;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  column-gap: 4px;
  cursor: pointer;
`;