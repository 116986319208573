import styled from 'styled-components';


export const StyledEnglishListenSettings = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 44px;

    @media (max-width: 1024px) {
        row-gap: 0;
    }

    .dropdown {
        width: 100%;

        @media (max-width: 1024px) {
            max-width: 130px;
        }
        
        > .pickHeader {
            height: 34px;
            border-radius: 4px;
        }
    }

    .ant-radio-button-wrapper {
        padding: 8px 16px !important;
        line-height: 24px;
    }

    > .setting {
        display: flex;
        flex-direction: column;

        > .header {
            display: flex;
            margin-bottom: 16px;
            align-items: center;

            @media (max-width: 1024px) {
                justify-content: space-between;
            }

            > .title {
                margin-right: 16px;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
            }

            > .hint {
                background-color: #fff;
                display: flex;
                padding: 6px 8px 6px 12px;
                font-size: 14px;
                line-height: 24px;
                font-weight: 500;
                column-gap: 8px;
                border-radius: 8px;

                @media (max-width: 1024px) {
                    border: 1px solid #D5D7DE;
                    border-radius: 8px;
                }
            }
        }
    }

    > .hr {
        background-color: rgb(242, 242, 242);
        margin: 30px -24px 20px;
        width: calc(100% + 48px);
        height: 8px;
    }
    
    .actionButton {
        margin-left: auto;
        padding: 4px 16px;
        font-size: 16px;
        border: 1px solid #D5D7DE;
        border-radius: 4px;
        column-gap: 8px;
    }
`;

export const StyledEnglishListenSettingsRows = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border: 1px solid #d5d7de;
    border-radius: 8px;


    @media (max-width: 1024px) {
        :has(> .options) {
            border: none;
        }
    }

    > .row {
        display: flex;
        padding: 20px 0;
        align-items: center;

        @media (max-width: 1024px) {
            padding: 0;
            flex-direction: column;
            align-items: flex-start;
        }

        &:not(:last-child) {
            border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
        }

        > .checkItem {
            display: flex;
            align-items: center;

            @media (max-width: 1024px) {
                width: 100%;
                padding: 8px 0 16px;

                &:not(:last-child) {
                    border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
                }
            }
        }

        .label {
            margin-bottom: 8px;
            color: #454b5c;
            font-size: 14px;
            line-height: 24px;
        }
    }

    > .check {
        display: flex;
        width: 100%;
        column-gap: 10px;

        .checkbox {
            margin: 0 32px;
        }

        .selectItem {
            width: 228px;
        }
    }

    > .noCheck {
        padding-left: 56px;
        padding-right: 56px;
        column-gap: 10px;

        @media (max-width: 1024px) {
            padding: 0;
        }

        :has(.radioGroup) {
            flex-direction: column;
            align-items: flex-start;
        }

        > .radioGroup {
            @media (max-width: 1024px) {
                padding-left: 16px;
                padding-bottom: 8px;
            }

            > .ant-radio-group {
                > * {
                    padding: 6px 0;
                }

                @media (max-width: 1024px) {
                    display: flex;
                    margin-left: 8px;
                    flex-direction: column;
                    row-gap: 8px;
                }
            }

            .ant-radio {
                .ant-radio-inner {
                    border-color: #212B36;
                }
            }
            .ant-radio-checked {
                .ant-radio-inner {
                    border-color: #ec7963;

                    &::after {
                        background-color: #ec7963;
                    }
                }
            }
        }

        > .item {
            @media (max-width: 1024px) {
                padding: 8px 16px 16px;
                width: 100%;
                border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
            }

            > .select {
                display: flex;
                padding-right: 21px;
                align-items: center;

                > .unit {
                    margin-left: 8px;
                    color: #454B5C;
                    font-size: 14px;
                }
            }
        }

        .subtitle {
            margin: 8px 0;
            color: #454b5c;
            font-size: 14px;
            line-height: 24px;

            @media (max-width: 1024px) {
                margin-left: 16px;
            }
        }
    }

    > .options {
        padding-left: 32px;
        padding-right: 32px;
        column-gap: 18px;

        @media (max-width: 1024px) {
            padding: 0;
            flex-direction: row;
        }

        > .radioGroup {
            display: flex;
            gap: 16px 18px;

            @media (max-width: 1024px) {
                flex-wrap: wrap;
            }
        }

        .option {
            display: flex;
            flex-direction: column;
            row-gap: 4px;

            > .checkbox {
                display: flex;
                padding-bottom: 4px;
                align-items: center;
                column-gap: 8px;
                cursor: pointer;

                .ant-checkbox {
                    margin-bottom: 6px;

                    + span {
                        padding: 0
                    }
                }

                .ant-checkbox-checked {
                    .ant-checkbox-inner {
                        background-color: #ec7963;
                        border: none;
                    }
                }
            }
        }
    }
`;

export const StyledTryListen = styled.div`
    display: flex;
    padding: 4px;
    align-items: center;
    column-gap: 4px;
    cursor:  ${({ isPlaying }) => isPlaying ? 'not-allowed' : 'pointer'};;
    align-self: baseline;

    > span {
        color: #5A5A70;
        font-size: 14px;
        line-height: 24px;
        text-decoration: underline;
    }
`;