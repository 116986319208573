import React from 'react';
import {
  Checkbox as AntdCheckbox,
  CheckboxProps as AntdCheckboxProps,
} from 'antd';
import { StyledCheckbox } from './Checkbox.style';


type Props = {
  label?: string;
}

export type CheckboxProps = AntdCheckboxProps & Props;

export const Checkbox = (props: CheckboxProps) => {

  const { className, value, disabled, checked, indeterminate, onChange, onClick, children } = props;

  return (
    <StyledCheckbox
      className={className}
      checked={checked}
      value={value}
      disabled={disabled}
      indeterminate={indeterminate}
      onChange={onChange}
      onClick={onClick}
      data-testid="Checkbox"
    >{children}</StyledCheckbox>
  );
};


type CheckedValue = string | number | boolean

export type CheckboxGroupProps = {
  name?: string;
  defaultValue?: string[];
  value?: string[];
  children?: React.ReactNode;
  onChange?: (checkedValue: CheckedValue[])=> void
}

export const CheckboxGroup = (props: CheckboxGroupProps) => {

  const { name, defaultValue, value, children, onChange } = props;

  const onChangeHandler = (list: CheckedValue[]) => {
    onChange && onChange(list);
  };

  return (
    <AntdCheckbox.Group
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={onChangeHandler}
    >
      <>
        {children}
      </>
    </AntdCheckbox.Group>
  );
};


