import { Api } from '@oneclass/ui-components';
import { stringify } from 'query-string';

export const postCreateExamPaper = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Exam/Create`, payload);
  return response;
};

export const putEditExamPaper = async (payload) => {
  const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Exam/Edit`, payload);
  return response;
};

export const putEditSpecifyExamPaper = async (payload, examUID) => {
  const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Exam/Edit/${examUID}`, payload);
  return response;
};


export const getQueryExamPaper = async () => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Exam/Query`);
  return response;
};

export const putExamPaperPublic = async (payload) => {
  const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Exam/Public`, payload);
  return response;
};

// 試卷預覽，已經棄用。
export const getExamPaperPreview = async (examUID) => {
  const queryString = stringify({ examUID });
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Exam/Preview?${queryString}`);
  return response;
};

// 查詢公開試卷列表
export const getExamPaperPublic = async (payload) => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Exam/Public`, payload);
  return response;
};
// 試卷收藏
export const putExamPaperFavorite = async (payload) => {
  const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Exam/Favorite`, payload);
  return response;
};

// 查詢組卷進度
export const getExamPaperExportProgress = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Export/GetFile`, payload);
  return response;
};

// 查詢組卷進度
export const getExamPaperEditCopy = async (examUID, action) => {
  const queryString = stringify({ examUID, action });
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Exam/Question?${queryString}`);
  return response;
};

// -----  新ＡＰＩ -----

// 建立試卷
export const postExamCreate = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Exam/CreateOne`, payload);
  return response;
};

// 編輯試卷
export const postExamEdit = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Exam/EditOne`, payload);
  return response;
};

// 取得試卷資訊 （for 編輯 複製）
export const postExamGetData = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Exam/Checkout`, payload);
  return response;
};

// 取得已出過之試卷資訊 （for 過濾試題）
export const getExamContent = async (payload) => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Exam/Content?eduSubject=${payload}`);
  return response;
};

// 編輯試卷 是否為公開
export const postExamEditOne = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Exam/EditOne`, payload);
  return response;
};

// 刪除試卷
export const deleteExamPaper = async (payload) => {
  const response = await Api.delete(`${process.env.REACT_APP_DOMAIN}/api/Exam/DeleteOne`, { data: payload });
  return response;
};

// 取得縣市地區
export const getDistricts = async () => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Exam/Audio/GetDistricts`);
  return response;
};

// 取得學校
export const getSchools = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Exam/Audio/GetSchools`, payload);
  return response;
};

export const getMemberPermission = async () => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Member/Permission`);
  return response;
};

export const getDesignList = async () => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/PaperPackage/DesignList`);
  return response;
};

export const getSubscribeList = async () => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/PaperPackage/SubscribeList`);
  return response;
};

export const subscribePackage = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/PaperPackage/Subscribe`, payload);
  return response;
};

export const createPaperPackage = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/PaperPackage/Create`, payload);
  return response;
};

export const getPaperPackageInfo = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/PaperPackage/Info`, payload);
  return response;
};

export const deletePaperPackage = async (payload) => {
  const response = await Api.delete(`${process.env.REACT_APP_DOMAIN}/api/PaperPackage`, { data: payload });
  return response;
};

export const savePaperPackage = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/PaperPackage/Edit`, payload);
  return response;
};