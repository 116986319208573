import ReactGA from 'react-ga4';

/**
 * footer
 * @param {'nani_teacher' | 'on_box' | 'fonts'} label
 */
export const clickFooterItem = (label) => ReactGA.event({
  category: 'footer',
  action: 'click_footer_item',
  label
});

/**
 * 命題方式
 * @param {'studentIntelligentProposition' | 'computerQuestion' | 'manualProposition' | 'equationDojo'} label
 */
export const chooseQuestionType = (label) => {
  const labelMap = (label_) => {
    switch (label_) {
      case 'studentIntelligentProposition':
        return 'student_intelligent_proposition';
      case 'computerQuestion':
        return 'computer_question';
      case 'manualProposition':
        return 'manual_proposition';
      case 'equationDojo':
        return 'equation_dojo';
      default:
        return label_;
    }
  };
  ReactGA.event({
    category: 'paper_config',
    action: 'choose_question_type',
    label: labelMap(label)
  });
};

/**
 * 步驟條點選
 * @param {step.title} label
 */
export const clickStep = (label) => ReactGA.event({
  category: 'common',
  action: 'click_step',
  label
});

/**
 * 返回首頁
 */
export const returnHome = () => ReactGA.event({
  category: 'common',
  action: 'return_home'
});

/**
 * 題型選擇
 * @param {'quesAndAns' | 'diff'} label
 */
export const changeSelectBy = (label) => {
  const labelMap = (label_) => {
    switch (label_) {
      case 'quesAndAns':
        return 'by_questions_and_answers';
      case 'diff':
        return 'by_difficulty';
      default:
        return label_;
    }
  };
  ReactGA.event({
    category: 'question_type_select',
    action: 'change_select_by',
    label: labelMap(label)
  });
};

/**
 * 過濾已出過試題
 * @param {'open_modal' | 'submit'} label
 */
export const filterUsedQuestions = (label) => ReactGA.event({
  category: 'question_type_select',
  action: 'filter_used_questions',
  label
});

/**
 * 拖曳題型順序
 */
export const dragQuestionTypeOrderSelect = () => ReactGA.event({
  category: 'question_type_select',
  action: 'drag_question_type_order'
});

/**
 * 已選/未選試題篩選器
 * @param {'all' | 'checked' | 'notCheck'} label
 */
export const selectedQuestionFilter = (label) => {
  const labelMap = (label_) => {
    switch (label_) {
      case 'notCheck':
        return 'not_checked';
      default:
        return label_;
    }
  };
  ReactGA.event({
    category: 'question_select',
    action: 'selected_questions_filter',
    label: labelMap(label)
  });
};

/**
 * 試題篩選器
 * @param {'search' | 'QUES_TYPE' | 'DIFFICULTY' | 'SOURCE' | 'KNOWLEDGE' | 'LEARN_CONTENT'} label
 */
export const setQuestionsFilter = (label) => {
  const labelMap = (label_) => {
    switch (label_) {
      case 'QUES_TYPE':
        return 'question_type';
      default:
        return String(label_).toLocaleLowerCase();
    }
  };
  ReactGA.event({
    category: 'question_select',
    action: 'set_questions_filter',
    label: labelMap(label)
  });
};

/**
 * 範圍篩選清單開關
 * @param {'open' | 'close'} label
 */
export const clickRangeFilterToggle = (label) => ReactGA.event({
  category: 'question_select',
  action: 'click_range_filter_toggle',
  label
});

/**
 * 範圍篩選清單選擇
 */
export const clickRangeFilterChapter = () => ReactGA.event({
  category: 'question_select',
  action: 'click_range_filter_chapter'
});

/**
 * 試題選擇放大題目
 * @param {id} label
 */
export const zoomInQuestionSelect = (label) => ReactGA.event({
  category: 'question_select',
  action: 'zoom_in_question',
  label
});

/**
 * 拖曳題型順序
 */
export const dragQuestionTypeOrderPreview = () => ReactGA.event({
  category: 'question_preview',
  action: 'drag_question_type_order'
});

/**
 * 試題順序調整
 * @param {'by_difficulty' | 'by_chapter' | 'random' | 'custom'} label
 */
export const setQuestionsOrder = (label) => {
  const labelMap = (label_) => {
    switch (label_) {
      case 'diff':
        return 'by_difficulty';
      case 'chapter':
        return 'by_chapter';
      default:
        return label_;
    }
  };
  ReactGA.event({
    category: 'question_preview',
    action: 'set_questions_order',
    label: labelMap(label)
  });
};

/**
 * 出題預覽放大題目
 * @param {id} label
 */
export const zoomInQuestionPreview = (label) => ReactGA.event({
  category: 'question_preview',
  action: 'zoom_in_question',
  label
});

/**
 * 常用設定儲存
 */
export const saveUserSetting = () => ReactGA.event({
  category: 'user_setting',
  action: 'save_user_setting',
});

/**
 * 點擊個人設定
 */
export const clickUserSetting = () => ReactGA.event({
  category: 'user_setting',
  action: 'click_user_setting',
});

/**
 * 複製試卷
 */
export const copyPaper = () => ReactGA.event({
  category: 'paper_list',
  action: 'copy_paper',
});

/**
 * 編輯試卷
 */
export const editPaper = () => ReactGA.event({
  category: 'paper_list',
  action: 'edit_paper',
});

/**
 * 預覽試卷
 */
export const previewPaper = () => ReactGA.event({
  category: 'paper_list',
  action: 'preview_paper',
});

/**
 * 刪除試卷
 */
export const deletePaper = () => ReactGA.event({
  category: 'paper_list',
  action: 'delete_paper',
});

/**
 * 試卷列表篩選器
 * @param {'eduSubject' | 'drawUp'} label
 */
export const setPaperListFilter = (label) => {
  const labelMap = (label_) => {
    switch (label_) {
      case 'eduSubject':
        return 'edu_subject';
      case 'drawUp':
        return 'draw_up';
      default:
        return label_;
    }
  };
  ReactGA.event({
    category: 'paper_list',
    action: 'set_paper_list_filter',
    label: labelMap(label)
  });
};

/**
 * 測驗列表篩選器
 * @param {'quizName' | 'eduSubject' | 'date'} label
 */
export const setExamListFilter = (label) => {
  const labelMap = (label_) => {
    switch (label_) {
      case 'quizName':
        return 'quiz_name';
      case 'eduSubject':
        return 'edu_subject';
      default:
        return label_;
    }
  };
  ReactGA.event({
    category: 'exam_list',
    action: 'set_exam_list_filter',
    label: labelMap(label)
  });
};

/**
 * 分享測驗 tab
 * @param {'testLink' | 'testCode' | 'qrCode'} label
 */
export const clickShareExamTab = (label) => {
  const labelMap = (label_) => {
    switch (label_) {
      case 'testLink':
        return 'link';
      case 'testCode':
        return 'test_code';
      case 'qrCode':
        return 'qr_code';
      default:
        return label_;
    }
  };
  ReactGA.event({
    category: 'exam_list',
    action: 'click_share_exam_tab',
    label: labelMap(label)
  });
};

/**
 * 經由連結分享測驗
 * @param {'copy' | 'link'} label
 */
export const shareExamByLink = (label) => ReactGA.event({
  category: 'exam_list',
  action: 'share_exam_by_link',
  label
});

/**
 * 分享連結複製說明
 * @param {'link' | 'test_code'} label
 */
export const copyShareExamManual = (label) => ReactGA.event({
  category: 'exam_list',
  action: 'copy_share_exam_manual',
  label
});

/**
 * 匯出成績
 */
export const exportScore = () => ReactGA.event({
  category: 'exam_list',
  action: 'export_score',
});

/**
 * 檢討測驗
 */
export const reviewExam = () => ReactGA.event({
  category: 'exam_list',
  action: 'review_exam',
});

/**
 * 刪除測驗
 */
export const deleteExam = () => ReactGA.event({
  category: 'exam_list',
  action: 'delete_exam',
});

/**
 * 預覽測驗
 */
export const previewExam = () => ReactGA.event({
  category: 'exam_list',
  action: 'preview_exam',
});

/**
 * 編輯測驗
 */
export const editExam = () => ReactGA.event({
  category: 'exam_list',
  action: 'edit_exam',
});