import React, { MouseEvent } from 'react';
import { StyledButton } from './Button.style';

export type ButtonProps = {
    className?: string;
    onClick?: (React.MouseEventHandler<HTMLDivElement> & ((event: MouseEvent<HTMLButtonElement>)=> void)) | undefined;
    children?: React.ReactNode;
    variant?: string;
    disabled?: boolean;
    isLoading?: boolean;
    type?: string;//default||solid
    textcolor?: string;
    borderRadius?: string;
};

export const Button = (props: ButtonProps) => {

  const { className, onClick, children, variant, disabled, isLoading, type = 'default', textcolor, borderRadius } = props;

  return (
    <StyledButton
      className={className}
      onClick={disabled || isLoading ? () => {return false;} : onClick}
      variant={variant}
      disabled={disabled}
      isLoading={isLoading}
      type={type}
      textcolor={textcolor}
      borderRadius={borderRadius}
      data-testid="Button"
    >
      {
        isLoading &&
        <svg className="spinner" width="14px" height="14px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle></svg>
      }
      {children}
    </StyledButton>
  );
};