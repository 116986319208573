import React from 'react';
import {
  RadioProps as AntRadioProps,
  RadioGroupProps as AntRadioGroupProps
} from 'antd';
import { StyledRadio, StyledRadioGroup } from './Radio.style';

type Props = {
    className: string
};

export type RadioProps = AntRadioProps & Props;

export const Radio = (props: RadioProps) => {
  const { className, value, disabled, checked, onChange, children } = props;
  return (
    <StyledRadio
      className={className}
      checked={checked}
      value={value}
      disabled={disabled}
      onChange={onChange}
      data-testid="Radio">
      {children}
    </StyledRadio>
  );
};


export type GroupProps = {
  className?: string;
}
export type RadioGroupProps = AntRadioGroupProps & GroupProps;

export const RadioGroup = (props: RadioGroupProps) => {
  const { options, value, onChange, optionType, buttonStyle, children, className } = props;

  return (
    <StyledRadioGroup
      className={className}
      options={options}
      onChange={onChange}
      value={value}
      optionType={optionType}
      buttonStyle={buttonStyle}
    >{children}</StyledRadioGroup>
  );
};