import styled from 'styled-components';

export const StyledStudentExamPaperListPage = styled.div.attrs(props => ({
  className: props.className
}))`
   .ant-tabs-tab + .ant-tabs-tab{
      margin: 0 0;
   }
   .ant-tabs-top > .ant-tabs-nav{
      margin: 0 0;
   }
   .ant-tabs-tab{
      padding: 10px 25px;
      color: ${(props) => props.theme.colors.text.text_05};
   }
`;