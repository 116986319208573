import {
////
} from 'views';

import { MainLayout } from 'layouts';
import {
  LoginPage,
  HomePage,
  MemberCenterPage,
  ComputerQuestionPage,
  ExamPaperListPage,
  StudentIntelligentPropositionPage,
  StudentExamPaperListPage,
  ManualPropositionPage,
  SearchPaperListPage,
  KnowledgePropositionPage,
  MaintenancePage,
  EquationDojoPage,
  FontsPage,
  TestDetailPage,
  MyPaperDetailPage,
  SharePaperDetailPage,
  EditPaperPackagePage
} from 'views';
import CreatePaperPackage from 'views/CreatePaperPackage';

const routes = [
  // {
  //   path: '/login',
  //   component: 'loginPageComponent',
  //   exact: true,
  // },
  {
    path: '/',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/',
        component: HomePage,
        name: 'home',
        exact: true,
      }
    ],
  },
  {
    path: '/login',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/login',
        component: LoginPage,
        name: 'login',
        exact: true,
      }
    ],
  },
  {
    path: '/memberCenter',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/memberCenter',
        component: MemberCenterPage,
        name: 'memberCenter',
        exact: true,
      }
    ],
  },
  {
    path: '/manualProposition',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/manualProposition',
        component: ManualPropositionPage,
        name: 'manualProposition',
        exact: true,
      }
    ],
  },
  {
    path: '/knowledgeProposition',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/knowledgeProposition',
        component: KnowledgePropositionPage,
        name: 'knowledgeProposition',
        exact: true,
      }
    ],
  },
  {
    path: '/computerQuestion',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/computerQuestion',
        component: ComputerQuestionPage,
        name: 'computerQuestion',
        exact: true,
      }
    ],
  },
  {
    path: '/equationDojo',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/equationDojo',
        component: EquationDojoPage,
        name: 'equationDojo',
        exact: true,
      }
    ],
  },
  {
    path: '/examPaperList',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/examPaperList',
        component: ExamPaperListPage,
        name: 'examPaperList',
        exact: true,
      }
    ],
  },
  {
    path: '/myPaperDetail/:redemptionCode',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/myPaperDetail/:redemptionCode',
        component: MyPaperDetailPage,
        name: 'myPaperDetail',
        exact: true,
      }
    ],
  },
  {
    path: '/studentIntelligentProposition',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/studentIntelligentProposition',
        component: StudentIntelligentPropositionPage,
        name: 'studentIntelligentProposition',
        exact: true,
      }
    ],
  },
  {
    path: '/studentExamPaperList',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/studentExamPaperList',
        component: StudentExamPaperListPage,
        name: 'studentExamPaperList',
        exact: true,
      }
    ],
  },
  {
    path: '/SearchPaperListPage',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/SearchPaperListPage',
        component: SearchPaperListPage,
        name: 'SearchPaperListPage',
        exact: true,
      }
    ],
  },
  {
    path: '/maintenancePage',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/maintenancePage',
        component: MaintenancePage,
        name: 'maintenancePage',
        exact: true,
      }
    ],
  },
  {
    path: '/fonts',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/fonts',
        component: FontsPage,
        name: 'fonts',
        exact: true,
      }
    ],
  },
  {
    path: '/testDetail/:quizId',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/testDetail/:quizId',
        component: TestDetailPage,
        name: 'testDetail',
        exact: true,
      }
    ],
  },
  {
    path: '/examPaperList/createPaperPackage',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/examPaperList/createPaperPackage',
        component: CreatePaperPackage,
        name: 'createPaperPackage',
        exact: true,
      }
    ],
  },
  {
    path: '/examPaperList/editPagePackage/:redemptionCode',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/examPaperList/editPagePackage/:redemptionCode',
        component: EditPaperPackagePage,
        name: 'editPaperPackage',
        exact: true,
      }
    ],
  },
  {
    path: '/sharePaperDetail/:redemptionCode',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/sharePaperDetail/:redemptionCode',
        component: SharePaperDetailPage,
        name: 'sharePaperDetail',
        exact: true,
      }
    ],
  },
];
export default routes;