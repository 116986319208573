
// *
// 控制各個頁面之間的操作行為。
//*
import React, { createContext, useState, useEffect } from 'react';
export const behaviorProviderContext = createContext(() => {});
export const Provider = ({ children }) => {

  const [behaviorData, setBehaviorData] = useState({
    goToExamList: false, // 於試卷列表頁面，判斷是否需要切換去測驗列表tab。
    createExam: false, // 於試卷列表頁面，判斷是否需要直接使用第一筆資料，開啟建立測驗之彈窗。
    openShareQuizModal: false, // 測驗列表，是否要直接使用第一筆資料，開啟分享測驗彈窗。
    goToPackageList: false, // 於試卷列表頁面，判斷是否需要切換去試卷包列表tab。
  });

  const behaviorDataProviderChange = (key, value) => {
    setBehaviorData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const value = { behaviorData, behaviorDataProviderChange };

  return (
    <behaviorProviderContext.Provider value={value}>
      {children}
    </behaviorProviderContext.Provider>
  );
};

const behaviorProvider = {};
behaviorProvider.Provider = Provider;
behaviorProvider.behaviorProviderContext = behaviorProviderContext;

export default behaviorProvider;


