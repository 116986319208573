import styled from 'styled-components';

export const StyledQuestionLimitHint = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 16px;
    color:${({ theme }) => theme.colors.support.error};
    background: ${({ theme }) => theme.colors.support.diffculty};
    margin: 0px 8px;
    flex-wrap: wrap;
    div{
        width: 100%;
    }
    ul{
        list-style-type: disc;
        margin:4px 0 0 8px;
    }
    @media (max-width: 1024px) {
        margin: 0 0;
        position: fixed;
        bottom: 84px;
        width: 100%;
        z-index: 1000;
    }
    svg{
        font-size: 20px;
        color: ${({ theme }) => theme.colors.support.error};
        @media (max-width: 1024px) {
            font-size: 20px;
        }
    }
`;