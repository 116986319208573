import styled from 'styled-components';

export const StyledStudentIpExamCompleteContent = styled.div.attrs(props => ({
  className: props.className
}))`
    padding: 76px 0;
    .pageTitle{
        font-size: 24px;
        font-weight:500;
    }
    .hint{
        color: ${({ theme }) => theme.colors.text.text_06};
        span{
            color: #4C99A5;
            border-bottom: 1px solid #4C99A5;
            cursor: pointer;
        }
    }
    > div{
        display: flex;
        justify-content: center;
    }
    @media (max-width: 1024px) {
        .hint{
            text-align: center;
        }
        .controlBtnBox{
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 1003;
            background-color: #fff;
            padding: 8px 24px 35px 24px;
            box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.05);
            >div{
                width: 100%;
            }
        }
    }
`;