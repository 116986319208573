import React from 'react';
import PropTypes from 'prop-types';
import { StyledContainer, StyledPaper } from './LayoutSettingPaper.style';

export const LayoutSettingPaper = ({ hasListen, title, children }) => {
  if (hasListen) {
    return (
      <StyledPaper>
        {title && (
          <div className='title'>
            {title}
          </div>
        )}
        <div className='children'>
          {children}
        </div>
      </StyledPaper>
    );
  } else {
    return <StyledContainer>{children}</StyledContainer>;
  }
};

LayoutSettingPaper.propTypes = {
  hasListen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
};
