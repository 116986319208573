import styled from 'styled-components';

export const StyledPaper = styled.div`
    &:not(:last-child) {
        margin-bottom: 28px;
    }

    > .title { 
        margin-bottom: 12px;
        font-size: 20px;
        font-weight: 500;

        @media (max-width: 1024px) {
            margin-bottom: 0;
            background: rgba(180, 188, 197, 0.16);
            padding: 12px 24px;
        }
    }

    > .children {
        background: rgba(180, 188, 197, 0.16);
        padding: 44px 48px;
        border-radius: 8px;

        @media (max-width: 1024px) {
            background: #fff;
            padding: 24px;
        }
    }
`;

export const StyledContainer = styled.div`
    padding: 32px 24px;
    border: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
    border-radius: 8px;
`;