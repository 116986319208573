import styled from 'styled-components';
export const StyledFilterBar = styled.div`
    position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	> *{
		&:not(:last-child){
			margin-right: 8px;
		}
	}
`;

export const StyledActionIcon = styled.div`
>div{
    width: 20px;
    height:20px;
}
    svg {
        cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'} ;
        font-size: 24px;
        color:${({ theme, disabled }) => disabled ? theme.colors.ui.ui_03 : theme.colors.ui.ui_07};
        margin-right: 0 !important;
        margin: 0 4px !important;
        &:not(:last-child){
            margin-right: 19.5px;
        }
    }
`;
export const StyledTestListMobile = styled.div.attrs(props => ({
  className: props.className
}))`

.pageTitle{
    font-size: 18px;
    padding: 4px 0 0 8px;
    font-weight:500;
}
.tableZone{
    > div > div >div{
        padding:12px 0 12px 16px;
        box-sizing: border-box;
    }
}
.tableEmpty{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
    >div{
        padding:4px 0 !important;
        color:${({ theme }) => theme.colors.text.text_05};
    }
    .makeATextPaperBtn{
        margin-top: 32px;
        color: #fff !important;
        padding: 8px 34px !important;
    }
}
.pageBlock{
    padding:34px 0 56px;
}
#detailModal{
    .modalContent{
        display: flex;
        justify-content: space-between;
        .modalRow{
            flex:1;
            .editBox{
                display: flex;
                justify-content: space-between;
                align-items: center;
                svg{
                    font-size: 20px;
                    cursor: pointer;
                }
                &.disabled{
                    >svg{
                        color: ${({ theme }) => theme.colors.ui.ui_05} !important;
                    }
                }
            }
        }
        .modalInnerTitle{
            font-weight: 500;
            font-size: 16px;
        }
        .modalInnerContent{
            padding:16px 20px;
            .modalInfoRow{
                display: flex;
                color:${({ theme }) => theme.colors.text.text_06};
                &:not(:first-child){
                    margin-top: 16px;
                }
                > div{
                    width: 30%;
                }
                > span{
                    width: 70%;
                    color:${({ theme }) => theme.colors.text.text_07};
                    word-break: break-all;
                }
            }
            .buttonZone{
                display: flex;
                >div{
                    &:not(:last-child){
                        margin-right: 8px;
                    }
                }
            }
            .modalPublicContent{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .modalPublicContentTitle{
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    svg{
                        font-size: 20px;
                    }
                }
                .modalHint{
                    padding: 4px 28px;
                    font-size: 11px;
                    color:${({ theme }) => theme.colors.text.text_06};
                }
            }
            .controlBox{
                display: flex;
                flex-wrap: wrap;
                border-bottom: 1px solid #E4E7EC;
                padding-bottom: 12px;

                >div{
                    flex-shrink: 0;
                    width: calc(50% - 3.5px);
                    margin-bottom: 12px;
                    &:nth-child(1),&:nth-child(3) {
                        margin-right: 7px;
                    }
                    >span{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
        .copyEditBtnBox{
            display: flex;
            align-items: center;
            padding-top: 12px;

            >div{
                padding:8px 12px;
                &:not(:last-child){
                        margin-right: 8px;
                    }
            }
        }
    }
}
@media (max-width: 1024px) {
    width: 100%;
    #testModal{
        .ant-tabs{
        /* padding-top:24px; */
        }
        .ant-tabs-tab-btn{
            font-size: 14px !important;
        }
        .ant-tabs-nav{
            margin-bottom: 16px !important;
        }
        .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
            padding: 16px 32px !important;
        }
        .ant-modal-body{
            padding: 0px 0px 24px 0px !important;
        }
        .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab{
            padding: 5px 15px !important;
        }
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap{
            padding:  0 10px;
        }
    }
    .filterBtnBox{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 5px 28px;
        background-color: rgb(250, 250, 250);
        height: 44px;
        >div{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        svg{
            font-size: 24px;
            margin-right: 0px !important;
            color: ${({ theme }) => theme.colors.ui.ui_05};
        }
        .active{
            padding: 5px 5px;
            background-color: ${({ theme }) => theme.colors.brand.brand02};
            border-radius: 8px;
            >svg{
                color: #fff;
            }
        }
    }
    .pageTitle{
        padding: 9px 24px;
        height: 47px;
    }
    .tableZone{
        min-height: calc(calc(var(--vh, 1vh) * 100) - 221px);
    }
    .tableHeader{
        display: none;
    }
    .tableRow{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px 24px;
        position: relative;
        .quizName{
            padding-right: 8.5% !important;
        }
        .itemImg{
            position: absolute;
            right: 7.5%;
            top: 0;
            bottom:0;
            margin:auto;
            display: flex;
            align-items: center;
            font-size: 20px;
            color:${({ theme }) => theme.colors.ui.ui_04}
        }
        &:not(:last-child){
            border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03} !important;
        }
        >div{
            border-bottom: 0px !important;
            padding: 0 0 !important;
            &:hover{
                background-color:unset !important;
            }
        }
        >div:nth-child(1){
            color:${({ theme }) => theme.colors.text.text_07};
            margin-bottom: 4px;
            font-size: 14px;
        }
        >div:nth-child(2){
            color:${({ theme }) => theme.colors.text.text_10};
            margin-bottom: 8px;
            font-size: 16px;
        }
        >div:nth-child(3){
            color:${({ theme }) => theme.colors.text.text_05};
            font-size: 14px;
        }
    }
    .modalInnerTitle{
        color:${({ theme }) => theme.colors.text.text_10};
    }
    .modalInnerContent{
        padding: 16px 0 !important;
    }
    .modalInfoRow{
        >div{
            text-align: right;
        }
        >span{
            padding-left: 20px;
        }
    }
    .blockDiv{
        border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
    }
    .buttonZone{
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
        >div{
            margin-right: 0px !important;
            margin-bottom: 12px;
        }
    }
    .copyEditBtnBox{
        >div{
            width: 50%;
            background-color: #fff;
            color: ${({ theme }) => theme.colors.brand.brand02};
            border: 1px solid #D5D7DE;
        }
    }
    .timeBox{
        >div{
            margin-bottom: 24px;
            >div{
                margin-bottom: 8px;
                color:${({ theme }) => theme.colors.text.text_06};
            }
            input{
                margin-left: 20px;
            }
        }
    }
    /* ------ */
    .modalContent{
        display: flex;
        flex-direction: column;
    }
    .ant-modal{
        position: absolute;
        bottom: 0;
        max-width:100%;
        padding: 0 0;
        margin: 0 0;
    }
    .ant-modal-content{
        position: absolute;
        bottom: 0;
        border-radius: 12px 12px 0 0;
        width: 100vw;
        height: 97vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 97);
        .ant-modal-close{
            right: 0;
        }
    }
    .ant-modal-header{
        display: flex;
        justify-content:center;
        align-items:center;
        padding: 21px 40px 21px;
    }
    .ant-modal-close-x{
        display: flex;
        justify-content:center;
        align-items:center;
        width: 50px;
        height:63px;
    }
    .ant-modal-body{
        padding: 16px 24px 16px;
        background-color: #fff;
        height: calc(calc(var(--vh, 1vh) * 97) - 64px);
        overflow: auto;
    }
    // 篩選彈窗
    .clearAllFilterBtn{
        position: absolute;
        right: 20px;
        top: 21px;
        font-size: 14px;
        font-weight: normal;
        color:${({ theme }) => theme.colors.text.text_04};
    }
    .clearAllFilterBtn.active{
        color: ${({ theme }) => theme.colors.brand.brand03};
    }
    #filterDrawer{
        .ant-modal-close{
            left: 0 !important;
            right: unset !important;
        }
        .ant-modal-body{
            height: calc(calc(var(--vh, 1vh) * 97) - 64px - 97px);
            overflow: auto;
        }
        .ant-modal-footer{
            padding: 10px 24px 42px 24px;
            background-color: #fff;
            box-shadow: rgb(0 0 0 / 10%) 0px -1px 4px;
            border-radius: 0px;
            >div{
                width: 100%;
                >div{
                    width: 100%;
                    margin-right: 0px;
                }
            }
        }
        .filterDrawerContent{
            margin-bottom: 24px;
            .title{
                font-size: 16px;
                margin-bottom: 16px;
            }
            .subTitle{
                margin-bottom: 8px;
                color:${({ theme }) => theme.colors.text.text_06};
            }
            &:not(:last-child){
                border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
            }
            .ant-radio-group{
                padding-bottom: 24px;
                >label{
                    margin-left: 0px !important;
                    margin: 0px 8px 8px 0;
                }
            }
        }
    }
}

#testModal{
    .ant-modal-title{
        justify-content: center;
    }
}
.copyBtn{
    cursor: pointer;
}

`;