import styled from 'styled-components';

export const StyledMemberCenterPage = styled.div.attrs(props => ({
  className: props.className
}))`
.flexTitle{
  display: flex;
  justify-content: space-between;
  .buttonZone{
    display: flex;
    &> div:not(:last-child){
      margin-right:8px;
    }
  }
  >div:first-child{
    font-weight: 500;
    font-size: 24px;
    color:${({ theme }) => theme.colors.text.text_07};
    &.subTitle{
      font-size: 18px;
    }
  }
}
.title{
    font-weight: 500;
    font-size: 24px;
    color:${({ theme }) => theme.colors.text.text_07};
    &.subTitle{
      font-size: 18px;
    }
}
.userDataBox{
  padding: 40px 64px;
  border: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
  box-sizing: border-box;
  border-radius: 8px;
  &.settingBox{
    padding: 44px 24px;
  }
  .userDataRow{
    display: flex;
    color:${({ theme }) => theme.colors.text.text_07};
    font-size: 16px;
    &> span{
      display: flex;
      align-items: center;
      margin-left:8px ;
      color: ${({ theme }) => theme.colors.support.success};
      >svg{
        margin-right: 4px;
      }
    }
    &>div{
      color:${({ theme }) => theme.colors.text.text_06};
      margin-right:8px ;
      
    }
    &.hint{
      color:${({ theme }) => theme.colors.text.text_05};
      font-size: 14px;
    }
  }
  .userDataRow:not(:last-child){
    margin-bottom: 16px;
  }
}
  .dragRowContent{
    display: flex;
    align-items: center;
    min-height: 82px;
    .dragRowCheckBox{
      flex: 0.2;
      justify-content: center;
      align-items: center;
    }
    .dragRowInnerRow{
      flex:0.8;
      padding: 8px 16px;
      .label{
        margin-bottom: 8px;
      }
      .paperNameLabel{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: unset !important;
        >span{
          margin-right: 10px;
        }
        svg{
          font-size: 18px;
          color: ${({ theme }) => theme.colors.ui.ui_06};
        }
        .paperNameTip{
          display: flex;
          cursor: pointer;
        }
      }
      .warningTag{
        color: ${({ theme }) => theme.colors.support.error};
      }
    }
  }
  .ant-radio-group-solid .ant-radio-button-wrapper{
    padding:8px 16px;
  }
  .radioLabel{
    margin-bottom: 8px;
    color:${({ theme }) => theme.colors.text.text_06};
  }
  .iconRadioList{
    svg{
        font-size: 18px;
    }
    >label{
        width: 100px;
        padding: 8px 10px !important;
        >span{
            display: flex;
            flex-direction: column;
            align-items: center;
            >svg{
                font-size: 50px;
                margin: 0 0;
            }
        }
    }
  }
  .mutiCheckBox{
    padding:8px 20px;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    font-size: 14px;
    &:not(:last-child){
      margin-right: 8px;
    }
  }
  .classInfo{
    display: flex;
    align-items: center;
    padding: 8px 0 0;
    > div{
        width: 80px;
        margin:0 8px;
        &:first-child{
            margin:0 8px 0 0;
        }
    }
  }
  .azBlock{
    border: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
    box-sizing: border-box;
    border-radius: 8px;
    display:inline-flex;
    flex-wrap: wrap;
    padding:12px  24px;
    margin-top: 16px;
    > .radioLabel{
        width: 100%;
    }
  }
  @media (max-width: 1024px) {
    .title{
      padding: 24px 16px 0px 24px;
      font-size: 18px;
    }
    .userDataBox{
      margin: 0px 24px;
      padding: 24px 24px;
    }
    .flexTitle{
      padding: 0 24px 150px 24px;
      align-items: center;
      >div:first-child{
        font-size: 18px;
      }
      svg{
        font-size: 20px;
        margin-right: unset;
        color: ${({ theme }) => theme.colors.ui.ui_05};
      }
    }
    // 常用設定 抽屜
    .searchParamsDrawer{
      .title{
        padding: 0px 0px;
      }
      .userDataBox{
        padding: 0px 0px;
        margin: 0px 0px;
        border: 0px;
      }
      .classInfo{
        >div{
          width: 65px;
        }
      }
      .mutiCheckBox{
        margin-bottom: 8px;
      }
      .mobileCloseBtn{
        font-size: 18px;
        transform: rotate(180deg);
        color: black;
      }
      .iconRadioList{
        >label{
          margin-left: 0px !important;
          margin-right: 8px;
          margin-bottom: 8px;
        }
      }
      .ant-drawer-title{
        font-size: 20px;
      }
      .ant-drawer-content-wrapper{
        height: unset !important;
      }
      .ant-drawer-content{
        overflow: hidden;
        border-radius:12px 12px 0 0;
        height: 97vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 97);
      }
      .ant-drawer-body{
        padding: 16px 24px 16px !important;
        height: calc(calc(var(--vh, 1vh) * 97) - 54px - 84px) !important;
        overflow-y: auto;
      }
    }
  }
`;