import { Api } from '@oneclass/ui-components';

// 建立測驗
export const postCreateQuiz = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Quiz/Create`, payload);
  return response;
};

// 取得使用者建立過的所有測驗
export const postQuizList = async (payload) => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Quiz/List?jwtToken=${payload.jwt}`);
  return response;
};

// 編輯測驗
export const patchQuizEdit = async (payload, jwt, quizUID) => {
  const response = await Api.patch(`${process.env.REACT_APP_DOMAIN}/api/Quiz/Edit?jwtToken=${jwt}&quizUID=${quizUID}`, payload);
  return response;
};

// 刪除測驗
export const deleteQuiz = async (jwt, quizUID) => {
  const response = await Api.delete(`${process.env.REACT_APP_DOMAIN}/api/Quiz/Delete?jwtToken=${jwt}&quizUID=${quizUID}`);
  return response;
};

// 取得單一測驗的 CSV
export const postQuizCSV = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Quiz/GetCSV`, payload);
  return response;
};

// 取得單一測驗詳情
export const postQuizDetail = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Quiz/GetDetail`, payload);
  return response;
};