import styled from 'styled-components';

export const StyledSuspendedQuestionsBar = styled.div.attrs((props) => ({
  className: props.className,
}))`
  background-color: ${({ theme }) => theme.colors.support.middle};
  display: flex;
  margin: 0 8px 16px;
  padding: 11px 16px;
  border-radius: 4px;
  align-items: flex-start;

  @media (max-width: 1024px) {
    margin: 0 24px;
  }

  > svg:first-child {
    color: ${({ theme }) => theme.colors.support.warning};
		font-size: 24px;
  }

  .banner {
    &__text {
      line-height: 24px;
      flex: 1;
    }

    &__close {
      cursor: pointer;
    }
  }
`;
