import React from 'react';
import styled from 'styled-components';
import { Radio, RadioProps, RadioGroupProps } from 'antd';
const { Group } = Radio;
export const StyledRadio: React.FC<RadioProps> = styled(Radio)`
    /* Code Here */
    
`;

export const StyledRadioGroup: React.FC<RadioGroupProps> = styled(Group)`
    /* Code Here */
    &.ant-radio-group-solid{
        .ant-radio-button-wrapper{
            border-radius: 4px;
            padding: 8px 12px;
            height: auto !important;
            border-left-width:1px;
            text-align: center;
            &:not(:first-child){
                margin-left: 8px;
                &::before{
                    display: none;
                }
            }
            
        }
        .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled){
            &:hover{
                color:${({ theme }) => theme.colors.brand.brand02};
                border-color: ${({ theme }) => theme.colors.brand.brand02};
            }
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
            background-color: ${({ theme }) => theme.colors.brand.brand02};
            border-color: ${({ theme }) => theme.colors.brand.brand02};
            &::before{
                display: none;
            }
            &:hover{
                color:${({ theme }) => theme.colors.ui.white};
                border-color: ${({ theme }) => theme.colors.brand.brand02};
            }
        } 
    }
    
`;