import styled from 'styled-components';
import { Modal } from '@oneclass/ui-components';

export const StyledMyPaperDetailPage = styled.div`
  width: 1024px;
  margin: auto;
  line-height: 1.5;
`;

export const StyledBackToList = styled.div`
  display: inline-flex;
  margin-bottom: 28px;
  color: #8B90A0;
  line-height: 24px;
  align-items: center;
  cursor: pointer;
`;

export const StyledMyPaperDetailContent = styled.div.attrs(props => ({
  className: props.className
}))`
padding: 0 32px;
.title {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.buttonGroup {
    display: flex;
    gap: 12px;
    padding: 5px 12px;

    .btn {
    height: 32px;

    &.outlined {
    color: #121232;
    border: 1px solid #D5D7DE;
  }

    &.contained {
        color: #fff;
        background: #EC7963;

        &.disabled{
          background: ${({ theme }) => theme.colors.text.text_02};
          color: ${({ theme }) => theme.colors.text.text_05};
        }
    }
  }
}
.lastEditTime {
    display: flex;
    justify-content: flex-end;
    margin: 16px 0;
    font-size: 14px;
    color: #8B90A0;
}
.tableZone{
    > div > div >div{
        padding:12px 0 12px 16px;
        box-sizing: border-box;
    }
}
.pageBlock {
  margin: 32px 0
}
`;

export const StyledDetailModal = styled.div.attrs(props => ({
  className: props.className
}))`
#detailModal{
  .modalContent{
        display: flex;
        justify-content: space-between;
        .modalRow{
            flex:1
        }
        .modalInnerTitle{
            font-weight: 500;
            font-size: 16px;
        }
        .modalInnerContent{
            padding:16px 20px;
            .modalInfoRow{
                display: flex;
                color:${({ theme }) => theme.colors.text.text_06};
                &:not(:first-child){
                    margin-top: 16px;
                }
                > div{
                    width: 20%;
                }
                > span{
                    width: 80%;
                    color:${({ theme }) => theme.colors.text.text_07};
                    word-break: break-all;
                }
            }
            .buttonZone{
                display: flex;
                >div{
                    padding: 4px 12px;
                    svg{
                        font-size: 16px;
                    }
                    &:not(:last-child){
                        margin-right: 8px;
                    }
                }
            }
            .modalPublicContent{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .modalPublicContentTitle{
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    svg{
                        font-size: 20px;
                    }
                }
                .modalHint{
                    padding: 4px 28px;
                    font-size: 11px;
                    color:${({ theme }) => theme.colors.text.text_06};
                }
            }
        }
        .saveButton{
            border: 1px solid #D5D7DE;
            width: 104px;
            padding: 5px 12px;
            color: #121232;
            margin-bottom: 16px;
        }
        .copyEditBtnBox{
            display: flex;
            align-items: center;
            >div{
                padding:4px 12px;
                &:not(:last-child){
                    margin-right: 8px;
                }
            }
            svg{
                font-size: 16px;
            }
        }
    }
}
`;

export const StyledHintModal = styled(Modal)`
  .ant-modal{
    width: 600px !important;
    height: 222px !important;
  }
  .ant-modal-header {
    padding: 40px 40px 24px;
  }
  .ant-modal-body {
    padding-bottom: 16px;
  }
  .ant-modal-footer {
    padding-top: 16px;
  }
  .hintModalContent{
    color: ${({ theme }) => theme.colors.text.text_06};
  }

  .footerBtn{
    .confirmBtn {
      background-color:  ${({ theme }) => theme.colors.support.error} !important;
      color: white !important;
    }
  }

    &.downloadFailed {
        .paperId {
            display:flex;
            background-color: #FAFAFC;
            margin: 16px 0 8px;
            padding: 12px;
            width: 100%;
            line-height: 24px;
            border-radius: 5px;
            justify-content: space-between;
        }

        .hint {
            color: #8B90A0;
            text-align: start;
        }

        .submit {
            margin-right: 0 !important;

            @media (max-width: 1024px) {
                width: 100%
            }
        }
    }

  @media (max-width: 1024px) {
    .ant-modal{
      width: unset !important;
      height: unset !important;
      position: absolute;
      bottom: 0;
      max-width:100%;
      padding: 0 0;
      margin: 0 0;
    }
    .ant-modal-content{
        position: absolute;
        bottom: 0;
        border-radius: 12px 12px 0 0;
        width: 100vw;
        height: auto !important;
        .ant-modal-close{
            right: 0;
        }
    }
    .ant-modal-header{
        display: flex;
        justify-content:center;
        align-items:center;
        padding: 21px 40px 21px;
    }
    .ant-modal-close-x{
        display: flex;
        justify-content:center;
        align-items:center;
        width: 50px;
        height:63px;
    }
    .ant-modal-body{
        padding: 16px 24px 16px;
        background-color: #fff;
        height: auto !important;
        overflow: auto;
    }
    .footerBtn{
      width: 100%;
    }
    .footerBtnBox{
      width: 100%;
      flex-direction: column;
      >div{
        width: 100%;
        margin-right: unset !important;
        &:nth-child(1){
          order: 2;
        }
        &:nth-child(2){
          order: 1;
          margin-bottom: 8px;
        }
      }
    }
  }
`;