export const $black = '#000000';
export const $white = '#FFFFFF';

// -------- $brand01 --------
export const $brand01_01 = '#FEF2F0';
export const $brand01_02 = '#FBE4E0';
export const $brand01_03 = '#FAD7D1';
export const $brand01_04 = '#F7C9C1';
export const $brand01_05 = '#F5BBB0';
export const $brand01_06 = '#F4AFA1';
export const $brand01_07 = '#F2A292';
export const $brand01_08 = '#F09482';
export const $brand01_09 = '#EE8773';
export const $brand01_10 = '#EC7963';
export const $brand01_11 = '#D26855';
export const $brand01_12 = '#B95648';

// -------- $brand02 --------
export const $brand02_01 = '#E8E8EB';
export const $brand02_02 = '#D0D0D6';
export const $brand02_03 = '#B8B8C2';
export const $brand02_04 = '#A0A0AD';
export const $brand02_05 = '#888898';
export const $brand02_06 = '#717184';
export const $brand02_07 = '#5A5A70';
export const $brand02_08 = '#41415B';
export const $brand02_09 = '#2A2A47';
export const $brand02_10 = '#121232';
export const $brand02_11 = '#050520';
export const $brand02_12 = '#000018';

// -------- $brand03 --------
export const $brand03_01 = '#EEF5F6';
export const $brand03_02 = '#DBEBED';
export const $brand03_03 = '#CAE1E4';
export const $brand03_04 = '#B7D6DB';
export const $brand03_05 = '#A5CBD1';
export const $brand03_06 = '#94C2C9';
export const $brand03_07 = '#82B8C0';
export const $brand03_08 = '#70ADB7';
export const $brand03_09 = '#5EA4AE';
export const $brand03_10 = '#4C99A5';
export const $brand03_11 = '#418791';
export const $brand03_12 = '#36757D';

// -------- $support --------
export const $support_easy = '#EEF7EF';
export const $support_middle = '#FFF6E8';
export const $support_diffculty = '#FFEDEC';
export const $support_success = '#4CAF50';
export const $support_warning = '#FF9800';
export const $support_error = '#F44336';

// -------- $primary --------
export const $primary_02 = '#121232';

// -------- Color scale --------
export const $scale = {
  $black,
  $white,
  $brand01: {
    $brand01_01,
    $brand01_02,
    $brand01_03,
    $brand01_04,
    $brand01_05,
    $brand01_06,
    $brand01_07,
    $brand01_08,
    $brand01_09,
    $brand01_10,
    $brand01_11,
    $brand01_12,
  },
  $brand02: {
    $brand02_01,
    $brand02_02,
    $brand02_03,
    $brand02_04,
    $brand02_05,
    $brand02_06,
    $brand02_07,
    $brand02_08,
    $brand02_09,
    $brand02_10,
    $brand02_11,
    $brand02_12,
  },
  $brand03: {
    $brand03_01,
    $brand03_02,
    $brand03_03,
    $brand03_04,
    $brand03_05,
    $brand03_06,
    $brand03_07,
    $brand03_08,
    $brand03_09,
    $brand03_10,
    $brand03_11,
    $brand03_12,
  },
  $support: {
    $support_easy,
    $support_middle,
    $support_diffculty,
    $support_success,
    $support_warning,
    $support_error
  },
  $primary: {
    $primary_02
  }
};