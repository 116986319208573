/* eslint-disable no-mixed-spaces-and-tabs */
import axios from 'axios';

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    const hostname = window.location.hostname;
    if (hostname.indexOf('oneclass.com.tw') > 0) {
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=oneclass.com.tw';
    } else {
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;';
    }
  }
};
export const getCookie = (name) => {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
};
export const checkLogin = (cookieLoginName) => {
  const gettoken = getCookie(cookieLoginName);
  if (gettoken) {
    return gettoken;
  }
  return '';
};
export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();

  const hostname = window.location.hostname;
  if (hostname.indexOf('oneclass.com.tw') > 0) {
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;domain=oneclass.com.tw';
  } else {
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }
};

export const exportFile = async (url, method, payload) => {
  axios({ url, method: method || 'get', data: payload, responseType: 'blob' }).then((res) => {
    const fileName = decodeURI(res.headers['content-disposition'].replace(/\w+;filename=(.*)/, '$1')).split(
      'UTF-8\'\'',
    )[1];
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  });
};


export const sliceTableData = (data, currentPage, limit) => {
  return data.slice((currentPage - 1) * limit, (currentPage - 1) * limit + limit);
};

export const setSelectOptions = (data, needNull = false) => {
  if (!data) return [];
  const result =
		data.length > 0 ? data.map((item) => {
		  const result = {
		    name: item.name,
		    value: item.code,
		  };
		  if (item.curriculum) result.curriculum = item.curriculum;
		  return result;
		})
		  : [];

  if (needNull) {
    result.push({
      name: '---',
      value: '',
    });
  }
  return result;
};

export const setRadioOptions = (data, needNull = false) => {
  if (!data) return [];
  const result =
		data.length > 0 ? data.map((item) => {
		  const result = {
		    label: item.name,
		    value: item.code,
		  };
		  if (item.curriculum) result.curriculum = item.curriculum;
		  return result;
		})
		  : [];

  if (needNull) {
    result.push({
      label: '---',
      value: '',
    });
  }
  return result;
};

export const sectionToChinese = (section) => {
  var chnNumChar = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  var chnUnitChar = ['', '十', '百', '千', '萬', '億', '萬億', '億億'];
  var strIns = '', chnStr = '';
  var unitPos = 0;
  var zero = true;
  if (section === 0) return '零';
  while (section > 0) {
    var v = section % 10;
    if (v === 0) {
      // if (!zero) {
      //   zero = true;
      //   chnStr = chnNumChar[v] + chnStr;
      // }
      let num = section / 10;
      if (num === 1) {
        chnStr = chnUnitChar[1];
      } else {
        chnStr = chnNumChar[num] + chnUnitChar[1];
      }
      break;
    } else if (chnStr !== '' && v === 1 && chnStr.length === 1) {
      zero = false;
      strIns = chnUnitChar[unitPos];
      chnStr = strIns + chnStr;
    } else {
      zero = false;
      strIns = chnNumChar[v];
      strIns += chnUnitChar[unitPos];
      chnStr = strIns + chnStr;
    }
    unitPos++;
    section = Math.floor(section / 10);
  }
  return chnStr;
};

export const intersection = (target, compare) => {
  const arrSet = new Set(compare);
  return arrSet.has(target);

};

export const intersectionArray = (target, compare) => {
  const arrSet = new Set(compare);
  let isFilter = false;
  target.forEach((item) => {
    if (arrSet.has(item)) {
      isFilter = true;
      return;
    }
  });
  return isFilter;
};

export const search = (target, compare) => compare.indexOf(target) !== -1;

// 判斷 該功能 權限是否開啟
export const checkAuthorityIsOpen = (functionName, authorityArray) => {
  let isOpen = false;
  if (authorityArray) {
    authorityArray.forEach((authority) => {
      if (functionName === authority.name) {
        isOpen = authority.authority;
      }
    });
  }
  return isOpen;
};

// 將字串 逐字判斷類型(中文/英文數字) 轉換成長度
export const transformStringWidth = (String) => {
  if (String) {
    let chineseNum = 0;
    let englishNum = 0;
    let numberNum = 0;

    const chineseString = new RegExp('[\u4E00-\u9FA5]+');
    const englishString = new RegExp('[A-Za-z]+');
    const numberString = new RegExp('[0-9]+');

    const stringLength = String.length;
    for (let i = 0 ; i <= stringLength - 1 ; i++ ) {
      if (chineseString.test(String.charAt(i))) chineseNum++;
      if (englishString.test(String.charAt(i))) englishNum++;
      if (numberString.test(String.charAt(i))) numberNum++;
    }
    return chineseNum * 14 + (englishNum + numberNum) * 9.3515;
  }
};