import styled from 'styled-components';

export const StyledTestQrCodeModalContent = styled.div.attrs(props => ({
  className: props.className
}))`
.testModalContent{
    svg{
        margin-right:0px;
    }
    .testModalLinkBox{
        margin-bottom: 24px;
        .endTime{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 16px;
            >span{
                font-size: 18px;
                color:${({ theme }) => theme.colors.text.text_06};
                @media (max-width: 1024px) {
                    font-size: 14px;
                }
            }
            >div{
                font-size: 24px;
                color:${({ theme }) => theme.colors.text.text_10};
                @media (max-width: 1024px) {
                    font-size: 18px;
                }
            }
        }
        .testModalLinkTitle{
            text-align: center;
            font-size: 24px;
            color:${({ theme }) => theme.colors.text.text_10};
            font-weight: 400;
            margin-bottom: 16px;
            @media (max-width: 1024px) {
                display: none;
            }
        }
        .titleText{
            text-align: center;
            font-size: 24px;
            color:${({ theme }) => theme.colors.text.text_10};
            font-weight: 400;
            margin-bottom: 16px;
            @media (max-width: 1024px) {
               font-size: 18px;
            }
        }
        .testModalLink{
            position: relative;
            padding:24px;
            background-color: ${({ theme }) => theme.colors.ui.ui_02};
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            >div{
                width: 100%;
                text-align:center;
                input{
                    text-align: center;
                }
            }
            .qrCodeBox{
                background-color: #fff;
                display: inline-block;
                padding: 20px 30px;
                border: 1px solid #D5D7DE;
                box-sizing: border-box;
                border-radius: 8px;
                margin-bottom: 12px;
            }
            .testModalLinkData{
                font-size: 24px;
                color:${({ theme }) => theme.colors.text.text_10};
                width: 95%;
                margin-right:2.5%;
                border:none;
                overflow:scroll;
                @media (max-width: 1024px) {
                    text-align: left;
                    font-size: 18px;
                }
            }
            svg{
                font-size: 21px;
                color:${({ theme }) => theme.colors.ui.ui_05};
                
            }
        }
    }
    .testModalManualBox{
        color:#242C3F;
        font-size: 20px;
        text-align: center;
        @media (max-width: 1024px) {
            font-size: 16px;
            text-align: left;
        }
    }
    .borderLine{
        border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};;
        padding-bottom: 48px;
        margin-bottom: 48px;
        @media (max-width: 1024px) {
            padding-bottom: 24px;
            margin-bottom: 24px;
        }
    }
    @media (max-width: 1024px) {
        padding: 0 24px;
    }
}
`;