import { Api } from '@oneclass/ui-components';

export const getExportPaperSetting = async (payload) => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Exam/Export/Setting`, payload);
  return response;
};

export const postExportPaper = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Exam/Export`, payload);
  return response;
};
