import styled from 'styled-components';

export const StyledMaintenancePage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    padding-top: 120px;

    >div{
        max-width: 100%;
        @media (max-width: 1024px) {
            width: 100%;
        }
    }

    .pageTitle{
        font-size: 30px;
        font-weight: bold;
        color: #EC7963;
        padding: 0 20px;
        text-align: center;
    }

    .imgBox{
        margin: 26px 0px 24px 0;
        max-width: 400px;
        img{
            width: 100%;
            max-width: 100%;
        }
    }

    .hintTitle{
        margin-bottom: 16px;
        font-size: 24px;
        color: #121232;
        font-weight: bold;
        padding: 0 20px;
        text-align: center;
    }

    .text{
        text-align: left;
        font-size: 16px;
        color: #3A4052;
        font-weight: 400;
        white-space: pre-wrap;
        word-wrap:break-word;
        padding: 0 20px;
        text-align: center;
    }

    @media (max-width: 1024px) {
        padding-top: 20%;
    }
`;