import { useState } from 'react';
import { AuthProvider } from '@oneclass/ui-components';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const StyledLoadingContent = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const useLoadingContent = () => {

  const antIcon = <LoadingOutlined style={{ fontSize: 70, color: '#c4c4c4' }} spin />;

  const { useAuthState } = AuthProvider;
  const [isLoading, setIsLoading] = useState(true);

  const renderLoadingContent = () => {
    return (
      <StyledLoadingContent className='loadingContent'>
        <Spin indicator={antIcon} />
      </StyledLoadingContent>
    );
  };
  return { renderLoadingContent, isLoading, setIsLoading };
};
