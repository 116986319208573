import { createGlobalStyle } from 'styled-components';


export const GlobalStyle = createGlobalStyle`
  .ant-message-notice-content{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #121232;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    height: 48px;
    .ant-message-custom-content{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      > span {
        display: block;
      }
    }
  }
`;