import styled from 'styled-components';

export const StyledFontsPage = styled.div.attrs(props => ({
  className: props.className
}))`
  .download-icon {
    padding-left: 24px;
  }
  .title {
    padding: 24px;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    border-bottom: 1px solid #d5d7de;
  }
  .description {
    padding: 24px;
    color: #3a4052;
    font-size: 16px;
    line-height: 24px;
    .subtitle {
      font-weight: 500;
    }
    .download-button {
      width: 168px;
      height: 48px;
      margin-bottom: 40px;
      padding: 0;
      a {
        display: flex;
        padding: 12px 16px;
        color: inherit;
        align-items: center;
      }
      img {
        margin-right: 8px;
      }
    }
    ol {
      margin-left: -1em;
      margin-bottom: 40px;
      img {
        margin-top: 16px;
        margin-bottom: 16px;
        margin-left: -1.5em;
      }
    }
  }
`;