import styled from 'styled-components';

export const StylePreviewQuesModalContent = styled.div`
    
`;

export const StyledAreaSelect = styled.div`
    .boxBottomLine{
        border-bottom: 1px solid #E4E7EC;
    }
    .yearRadioList{
        >label{
            padding: 8px 32px !important;
            margin: 0 12px 16px 0 !important;
        }
    }
    .eduRadioList{
        >label{
            padding: 8px 30px !important;
            margin: 0 12px 16px 0 !important;
        }
    }
    .subjectRadioList{
        >label{
            padding: 8px 30px !important;
            margin: 0 12px 16px 0 !important;
        }
    }
    .versionRadioList{
        >label{
            padding: 8px 30px !important;
            margin: 0 12px 16px 0 !important;
        }
    }
    .footerBtn{
        padding: 12px 24px 24px 24px;
        >div{
            margin: 0 0;
        }
    }
    .title{
        font-size: 16px;
    }
    .subTitle{
        font-size: 14px;
        color: ${(props) => props.theme.colors.text.text_06};
    }
    .garyText{
        color: ${(props) => props.theme.colors.text.text_06};
    }
`;

export const StyledFilter = styled.div`
    .footerBtn{
        padding: 12px 24px 24px 24px;
    }
    .ant-tree-switcher{
        order: 3;
    }
    .ant-tree-treenode{
        margin-bottom: 16px;
        .previewBtn{
            margin-right: 29px;
            @media (max-width: 1024px){
                margin-right: 0px;
            }
        }
    }
    .ant-tree-node-content-wrapper, .ant-tree-treenode{
        width: 100%;
    }
    .ant-tree-title{
        >div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: ${(props) => props.theme.colors.text.text_07};
            >div{
                margin-left: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .allSelected{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 17px;
        border-bottom: 1px solid #E4E7EC;
        margin-bottom: 17px;
        .ant-checkbox-inner{
            border-radius: 2px;
            border: 1px solid #d9d9d9;
        }
        >svg{
            font-size: 20px;
            color: ${({ theme }) => theme.colors.ui.ui_05};
        }
        >div{
            >label{
                margin-right: 12px;
            }
        }
        >div:nth-child(1){
            color: ${(props) => props.theme.colors.text.text_07};
        }
    }
    .ant-tree-switcher{
        display: flex;
        align-items: center;
        justify-content: center;
        >svg{
            color: ${({ theme }) => theme.colors.ui.ui_04};
            font-size: 13px;
            transition: 0.3s;
            transform: rotate(90deg);
        }
    }
    .ant-tree-switcher_open{
        >svg{
            transform: rotate(-90deg);
        }
    }
`;

export const StyledQuestionsList = styled.div`
    .questionTableBody{
        min-height: auto;
        max-height: none;
    }
    .pageBlock{
        margin: 44px 0px 12px;
    }
    .footerBtn{
        padding: 12px 12px 23px 12px;
        display: flex;
        align-items: center;
        border-top: 0px !important;
        >div{
            width: 50%;
            padding: 12px 16px;
            @media (min-width: 425px){
                width: auto;
            }
        }
    }
    @media (max-width: 1024px) {
        .pageBlock{
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 83px;
        }
    }
`;

export const StyledStudentWrongQuestionListContent = styled.div`
    padding: 0 24px 150px 24px;
    @media (min-width: 425px) {
        padding: 0 60px;
    }
    .ant-checkbox-inner{
        border-radius: 50%;
    }
    .selectAllBox{
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
        .stepTitle{
            margin: 0 0;
        }
        >div:nth-child(1){
            font-size: 18px;
            color: ${(props) => props.theme.colors.text.text_10};
        }
        >div:nth-child(2){
            color: ${(props) => props.theme.colors.text.text_05};
            >label{
                margin-right:8px;
            }
        }
        .allSelected{
            color: ${(props) => props.theme.colors.text.text_08};
        }
    }
    .perQuesItem{
        border: 1px solid #E4E7EC;
        border-radius: 8px;
        margin-bottom: 24px;
    }
    .Selected{
        border: 1px solid ${({ theme }) => theme.colors.scale.$primary.$primary_02};
        .checkbox{
            overflow: hidden;
            border-radius: 8px 8px 0 0;
            color: ${({ theme }) => theme.colors.text.white};
            background-color: ${({ theme }) => theme.colors.scale.$primary.$primary_02};
            border-bottom: 1px solid ${({ theme }) => theme.colors.scale.$primary.$primary_02};
            .ant-checkbox-checked .ant-checkbox-inner{
                background-color: #fff !important;
                border-color: #fff !important;
            }
            .ant-checkbox-checked .ant-checkbox-inner:after{
                border-color: ${({ theme }) => theme.colors.scale.$primary.$primary_02};
            }
        }
    }
    .checkbox{
        padding: 12px 20px;
        color: ${(props) => props.theme.colors.text.text_05};
        border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
        >div{
            >label{
                margin-right:10px;
            }
        }
    }
    .questionContent{
        padding: 16px 16px;
        @media (min-width: 425px){
            padding: 24px 24px;
        }
    }
    .questionDetail{
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
        padding-bottom: 16px;
        .title{
            margin-bottom: 16px;
            >span{
                padding: 4px 16px;
                margin-bottom: 16px;
                background-color: ${({ theme }) => theme.colors.scale.$brand03.$brand03_01};
                border-radius: 16px;
                overflow: hidden;
                color: ${({ theme }) => theme.colors.brand.brand03};
            }
        }
        .content{
            margin-bottom: 32px;
            color: ${(props) => props.theme.colors.text.text_08};
            img{
                max-width: 100%;
            }
            a{
                cursor: auto;
                color: ${(props) => props.theme.colors.text.text_08} !important;
            }
            span{
                color: ${(props) => props.theme.colors.text.text_08} !important;
            }
        }
    }
    .bottomContent{
        .answerBox{
            display: flex;
            align-items: center;
            margin: 20px 0 28px 0;
            >div:nth-child(1){
                color: ${(props) => props.theme.colors.text.text_08};
                margin-right: 24px;
                @media (max-width: 1024px) {
                    margin-right: 12px;
                }
            }
            >div:nth-child(2){
                font-size: 16px;
                line-height: 16px;
                display: flex;
                align-items: center;
                >div{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    height: 32px;
                    border: 1px solid #E4E7EC;
                    border-radius: 8px;
                    overflow: hidden;
                    margin-right: 12px;
                    @media (max-width: 1024px) {
                        margin-right: 6px;
                    }
                }
            }
            .rightAnswer{
                color: #fff;
                background: #4CAF50;
                border: 1px solid #4CAF50 !important;
            }
            .wrongAnswer{
                background-color: #F44336;
                color: #fff;
                border: 1px solid #F44336 !important;
            }
            .noAnswer{
                margin-left: 24px;
                border: 0px !important;
                width: auto !important;
                color: #F44336 !important;
                margin-right: 0px;
                @media (max-width: 1024px) {
                    margin-left: 6px;
                }
            }
        }
        .iGetThatBtn{
            width: 100%;
            padding: 8px 0;
            text-align: center;
            color: ${(props) => props.theme.colors.text.text_10};
            background: #FFFFFF;
            border: 1px solid #E4E7EC;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
            border-radius: 4px;
            overflow: hidden;
        }
        @media (min-width: 425px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            .answerBox{
                margin: 0 0;
                >div:nth-child(1){

                }
                >div:nth-child(2){

                    >div{
                        
                    }
                }
            }
            .iGetThatBtn{
                width: auto;
                padding: 8px 16px;
                box-shadow: 0px 0px 0px 0px;
            }
        }
    }
`;

export const StyledStudentWrongQuestionList = styled.div.attrs(props => ({
  className: props.className
}))`

    min-height: calc(100vh - 118px);
    @media (min-width: 425px) {
        min-height: auto;
    }
    .ant-notification{
        z-index: 1200;
        @media (max-width: 1024px) {
            bottom: 70px !important;
        }
    }
    .ant-notification-notice{
        background-color: #13131A;
        padding: 11px 16px !important;
    }
    .ant-notification-notice-message{
        margin-bottom: 0px !important;
        color: #fff !important;
        font-size: 14px !important;
    }
    .ant-steps{
        margin-bottom: 24px;
        padding: 12px 43px;
        width: 100%;
        background-color:${({ theme }) => theme.colors.ui.ui_01};
        @media (min-width: 425px) {
            padding: 25px 290px;
            background-color: #fff;
            border-bottom: 1px solid #E4E7EC;
        }
    }
    .emptyMsg{
        color: ${({ theme }) => theme.colors.text.text_05};
        font-size: 16px;
        text-align: center;
        margin-top: 50px;
    }
    .footerBtn{
        z-index: 999;
        position: fixed;
        left: 0px;
        bottom: 0px;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
        >div{
            width: 100%;
            margin: 0 0;
        }
        @media (min-width: 425px) {
            position: relative;
            box-shadow: 0px 0px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 1px solid #E4E7EC;
            >div{
                width: auto;
            }
        }
    }
    .previewQuesModal{
        .ant-modal{
            max-width: 792px;
        }
        @media (max-width: 1024px) {
            .ant-modal-title{
                font-size: 18px;
                width: 87%;
                color:${({ theme }) => theme.colors.scale.$primary.$primary_02};
            }
            .ant-modal-header{
                padding: 40px 40px 27px;
            }
            .ant-modal{
                width: auto !important;
                max-width:100%;
                top: 0px;
                padding: 0 0;
                margin: 0 0;
            }
            .ant-modal-content{
                min-height: calc(var(--vh, 1vh) * 100);;
            }
            .ant-modal-body{
                padding: 0px 24px 70px;
            }
            .pageBlock{
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 34px;
            }
        }
    }
    .stepTitle{
        font-size: 18px;
        color: ${(props) => props.theme.colors.text.text_10};
        margin-bottom: 24px;
    }
    @media (max-width: 1024px) {
        .ant-select-show-arrow{
            display: none;
        }
    }
    @media (min-width: 425px) {
        .stepTitle{
            display: none;
        }
    }
    .previewBtn{
        cursor: pointer;
        display: flex;
        align-items: center;
        >svg{
            font-size: 20px;
            color: ${({ theme }) => theme.colors.ui.ui_05};
        }
        >span{
            font-size: 14px ;
            color: ${(props) => props.theme.colors.text.text_06};
            @media (max-width: 1024px) {
                display: none;
            }
        }
    }
    .isEmpty{
        >div{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 20% 0 0 0;
            >span{
                font-size: 16px;
                margin-bottom: 40px;
                color: ${(props) => props.theme.colors.text.text_06};
            }
        }
    }
    .ant-pagination-options{
        display: flex;
        margin: 16px 0px 0px;
        justify-content: center;
        align-items: center;
    }
    .selectedNum{
        color:${({ theme }) => theme.colors.ui.ui_06};
    }
    .preStepBtn{
        color: ${(props) => props.theme.colors.text.text_05};
        background-color: #fff;
        border: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
        border-radius: 4px;
    }
`;

