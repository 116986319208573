import styled from 'styled-components';

export const StyledExamPaperHeader = styled.div.attrs(props => ({
  className: props.className
}))`
	display: flex;
	justify-content: space-between;
	margin:0 0 35px;
	padding: 0 20px;
	.pageTopTitle{
		font-size: 20px;
		color: ${({ theme }) => theme.colors.text.black};
		font-weight: normal;
		display: flex;
		align-items: center;
		@media (max-width: 1024px) {
            font-size: 16px;
			line-height:16px
        }
		svg{
			margin-left: 8px;
			font-size: 16px;
            cursor: pointer;
		}

	}

	.button-list {
		display: flex;
		gap: 8px;
	}
	.redo{
		display: flex;
		align-items: center;
		cursor: pointer;
		width: 107px;
		border: 1px solid ${({ theme }) => theme.colors.ui.ui_05};
		padding: 5px 12px;
		border-radius: 4px;
		.redo-img {
			margin-right: 5px;
			margin-bottom: 3px;

		}

		&:hover {
      background: ${({ theme }) => theme.colors.ui.ui_04};
			opacity: 0.8;
		}
	}

	.returnHome{
		border: 1px solid ${({ theme }) => theme.colors.ui.ui_05};
		color: ${({ theme }) => theme.colors.text.text_09};
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 5px 12px;
		border-radius: 4px;
		@media (max-width: 1024px) {
			display: none;
		}
		&:hover {
      background: ${({ theme }) => theme.colors.ui.ui_04};
			opacity: 0.8;
		}
	}
    .modalContent{
		color:${({ theme }) => theme.colors.text.text_06}
	}
    .mb20{
        margin-bottom: 20px;
    }
	.setupBtn{
		display: none;
		color: #4C99A5;
	}
	@media (max-width: 1024px) {
		padding-top: 12px;
		margin:0 0 10px;

		.showSetupBtn{
			display: block ;
		}
	}
`;