import styled from 'styled-components';

export const StyledStudentIpExamSettingContent = styled.div.attrs(props => ({
  className: props.className
}))`
    padding: 32px 16px;
    width: 100%;
    .pageTitle{
        font-size: 18px;
        padding-left: 16px;
        font-weight:500;
    }

    .cardList{
        display: flex;
        /* justify-content: space-between; */
        > div:not(:last-child){
            margin-right: 32px;
        }
    }
    .radioList{
        display: flex;
    }
    .message{
        display: flex;
        align-items: center;
        color: red;
        padding: 4px 11px;
    }
    .quesCount{
        color:${({ theme }) => theme.colors.text.text_06}
    }
    @media (max-width: 1024px) {
        padding: 24px 24px;
        .pageTitle{
            padding: 0 0;
        }
        .cardList{
            display: flex;
            flex-direction: column;
            >div{
                margin: 0 0 16px 0;
                width: 100%;
                max-width: 100%;
            }
        }  
        .radioList{
            display: flex;
            flex-direction: column;
            >div{
                >label{
                    margin: 0 8px 8px 0;
                    margin-left: 0px !important;
                }
            }
            >div:nth-child(1){
                order: 2;
            }
            >div:nth-child(2){
                order: 1;
                padding: 8px 0;
            }
        } 
    }
`;