import styled from 'styled-components';

export const StyledHomeAreaSelectContent = styled.div`
display: flex;
flex:1;
border: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
border-radius: 8px;

	@media (max-width: 1024px) {
			border: none
	}

	.checkBoxRow{
		&:not(:last-child){
			margin-bottom: 8px;
		}
	}
	.stepHint{
			color:${({ theme }) => theme.colors.text.text_06}
		}
	.areaBorder{
		background-color: ${({ theme }) => theme.colors.ui.ui_03};
		width: 100%;
		height: 1px;
	}
	.ant-radio-button-wrapper{
		padding: 8px 16px;
		border-color:${({ theme }) => theme.colors.text.text_06};
	}
`;

export const UiSelectList = styled.div`
`;
