import styled from 'styled-components';

export const ButtonBlock = styled.div`
  display: flex;
  gap: 16px;
  .leave {
    background: ${({ theme }) => theme.colors.text.text_03};
    border: 1px solid ${({ theme }) => theme.colors.brand.brand02};

    &:hover {
      background: ${({ theme }) => theme.colors.text.text_05};
    }
  }

  .think {
    background: ${({ theme }) => theme.colors.brand.brand02};
    color: white;

    &:hover {
      background: #3b54ae;
    }
  }
`;

export const TextBlock = styled.div`
  color: ${({ theme }) => theme.colors.text.text_09}
`;